import { createContext, useContext } from "react";
export type CsPartContextType = {
  id: string;
  isDisabled: boolean;
  forSale: boolean;
  type: string;
  partNumber: string;
  partName: string;
  spec: string;
  purpose: string;
  supplier: string;
  contactPerson: string;
  contact: string;
  hyperlink: string;
  manager: string;
  site: string;
  storage: string;
  price: number;
  quantity: number;
  note: string;
};
const CsPartContext = createContext<CsPartContextType>({} as CsPartContextType);
const useCsPart = () => useContext(CsPartContext);
export { useCsPart };
