import "./smartdrop.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useSmartdrop } from "../../contexts/smartdrop";
// api
import { queryJsonArray, deleteRowByNo } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";

export default function Smartdrop() {
  const { user } = useAuth();
  const smartdropContext = useSmartdrop();
  const navigate = useNavigate();

  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [selectedSerial, setSelectedSerial] = useState("");

  const [content, setContent] = useState("");
  const [isContentHidden, setIsContentHidden] = useState(true);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let acc_cs = 0;
    if (user) {
      acc_cs = user.acc_cs;
    }
    search();
  }, []);

  ////////////////////////// Search Button
  function onSearchButtonClicked() {
    search();
  }
  function search() {
    setIsContentHidden(true);

    let query = "SELECT * FROM smartdrop";
    query += " WHERE status = 'stock'";
    query += " ORDER BY serial Asc";
    queryJsonArray("/smartdrop/query", query).then((jsonData: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < jsonData.length; i++) {
        let oneRowJson: Object | null = jsonData[i];
        let purchaseDate: number = U.intValueFromJson(oneRowJson, "purchaseDate");
        let purchaseDate2: string = U.stringDateFromEightDigitIntDate(purchaseDate);
        oneRowJson = U.addStringToJson(oneRowJson, "purchaseDate2", purchaseDate2);
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }
      setJsonDataSource(newJsonData);
    });
  }

  function onSaleButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToSmartdropContext(selectedRow);
    navigate("/smartdrop/sale/new");
  }
  function copyToSmartdropContext(obj: Object) {
    smartdropContext.serial = U.stringValueFromJson(obj, "serial");
    smartdropContext.status = "stock";
    smartdropContext.modelName = U.stringValueFromJson(obj, "modelName");
    smartdropContext.purchaseDate = U.stringValueFromJson(obj, "purchaseDate");
    smartdropContext.manufacturer = U.stringValueFromJson(obj, "manufacturer");
    smartdropContext.purchasePrice = U.stringValueFromJson(obj, "purchasePrice");
    smartdropContext.purchaseDraft = U.stringValueFromJson(obj, "purchaseDraft");
    smartdropContext.cameraModel = U.stringValueFromJson(obj, "cameraModel");
    smartdropContext.swVersion = U.stringValueFromJson(obj, "swVersion");
    smartdropContext.mainboardVersion = U.stringValueFromJson(obj, "mainboardVersion");
    smartdropContext.licenseKey = U.stringValueFromJson(obj, "licenseKey");
    smartdropContext.registrant = U.stringValueFromJson(obj, "registrant");
    smartdropContext.purchaseNote = U.stringValueFromJson(obj, "purchaseNote");
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedRow(e.selectedRowsData[0]);
      setSelectedSerial(e.selectedRowsData[0].serial);

      setIsContentHidden(false);
      setContent(e.selectedRowsData[0].purchaseNote);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>SmartDrop 재고</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>
            <div className={"flex-item1"}>
              <Button text="출고" onClick={onSaleButtonClicked} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <Paging defaultPageSize={20} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="serial" caption="Serial" />
              <Column dataField="modelName" caption="모델명" />
              <Column dataField="purchaseDate2" caption="구매일" />
              <Column dataField="purchasePrice" caption="구매금액" dataType="number" format="#,##0" />
              <Column dataField="cameraModel" caption="카메라 모델" />
              <Column dataField="swVersion" caption="SW 버전" />
              <Column dataField="mainboardVersion" caption="메인보드 버전" />
              <Column dataField="manufacturer" caption="생산업체" />
              <Column dataField="licenseKey" caption="License Key" />
              <Column dataField="purchaseNote" caption="입고 비고" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="serial" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>

          <div className={"flex-stretch"}>
            <div className={"flex-item2"} hidden={isContentHidden}>
              <TextArea label="입고 비고" value={content} readOnly={true} autoResizeEnabled={true} maxHeight={800} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
