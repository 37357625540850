import "./ctPartNew.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCtPart } from "../../contexts/ctPart";
// api
import { uploadJson, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { CheckBox } from "devextreme-react/check-box";
import { TextBox } from "devextreme-react/text-box";
import { SelectBox } from "devextreme-react/select-box";

export default function CtPartNew() {
  const { user } = useAuth();
  const ctPart = useCtPart();
  const navigate = useNavigate();

  const [userId, setUserId] = useState<string>("");
  const [partNumber, setPartNumber] = useState("");
  const [isPartNumberReadOnly, setIsPartNumberReadOnly] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [name, setName] = useState("");
  const [forSale, setForSale] = useState(false);
  const [description, setDescription] = useState("");
  const [supplier, setSupplier] = useState("");
  const [manager, setManager] = useState("");

  useEffect(() => {
    if (user) {
      setUserId(user.id);
    }
    setIsEdit(false);
    setIsPartNumberReadOnly(false);
    if (!ctPart.partNumber) return;
    if (ctPart.partNumber.length === 0) return;
    // edit 버튼으로 진입한 경우: context 변수로부터 element에 복사
    setPartNumber(ctPart.partNumber);
    setIsEdit(true);
    setIsPartNumberReadOnly(true);
    setName(ctPart.name);
    setForSale(ctPart.forSale);
    setDescription(ctPart.description);
    setSupplier(ctPart.supplier);
    setManager(ctPart.manager);
  }, []);

  function onPartNumberTextBoxValueChanged(e: string) {
    setPartNumber(e);
  }
  function onNameTextBoxValueChanged(e: string) {
    setName(e);
  }
  function onForSaleCheckBoxValueChanged(e: boolean) {
    setForSale(e);
  }
  function onDescriptionTextBoxValueChanged(e: string) {
    setDescription(e);
  }
  function onSupplierTextBoxValueChanged(e: string) {
    setSupplier(e);
  }
  function onManagerTextBoxValueChanged(e: string) {
    setManager(e);
  }

  function onUploadButtonClicked() {
    if (partNumber.length === 0) {
      alert("Enter value to Part Number!", "Error");
      return;
    }
    if (name.length === 0) {
      alert("Enter value to 품명!", "Error");
      return;
    }
    if (isEdit) uploadEditPart();
    else uploadNewPart();
  }

  async function uploadNewPart() {
    let obj: Object = {
      partNumber: partNumber,
      name: name,
      forSale: forSale,
      description: description,
      supplier: supplier,
      manager: manager,
    };
    await uploadJson("/ct/uploadpart", obj).then((response_status: number) => {
      console.log(response_status);
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      alert("Failed to upload!", "Error");
      return;
    });
  }

  async function uploadEditPart() {
    let obj: Object = {
      partNumber: partNumber,
      name: name,
      forSale: forSale,
      description: description,
      supplier: supplier,
      manager: manager,
    };
    await uploadJson("/ct/overwritepart", obj).then((response_status: number) => {
      console.log(response_status);
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      alert("Failed to upload!", "Error");
      return;
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>카트리지 부품 등록</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox
                label="Part Number"
                value={partNumber}
                readOnly={isPartNumberReadOnly}
                valueChangeEvent="keyup"
                onValueChange={onPartNumberTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <CheckBox text="판매용" value={forSale} onValueChange={onForSaleCheckBoxValueChanged} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="품명" value={name} valueChangeEvent="keyup" onValueChange={onNameTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="공급업체"
                value={supplier}
                valueChangeEvent="keyup"
                onValueChange={onSupplierTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="담당자"
                value={manager}
                valueChangeEvent="keyup"
                onValueChange={onManagerTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Description"
                value={description}
                valueChangeEvent="keyup"
                onValueChange={onDescriptionTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
