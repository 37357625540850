import "./ctUsageNew.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCt } from "../../contexts/ct";
// api
import { uploadJson, setDeliveredPurchaseRequest } from "../../api/auth";
// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { CheckBox } from "devextreme-react/check-box";
import DateBox from "devextreme-react/date-box";
import { setuid } from "process";

export default function CtUsageNew() {
  const { user } = useAuth();
  const ctContext = useCt();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState("");
  const [type, setType] = useState("");
  const [date, setDate] = useState<Date>();
  const [registrant, setRegistrant] = useState("");
  const [itemCatNo, setItemCatNo] = useState("");
  const [isItemCatNoReadOnly, setIsItemCatNoReadOnly] = useState(true);
  const [itemLotNo, setItemLotNo] = useState("");
  const [isItemLotNoReadOnly, setIsItemLotNoReadOnly] = useState(true);
  const [itemForSale, setItemForSale] = useState(false);
  const [isItemForSaleReadOnly, setIsItemForSaleReadOnly] = useState(true);
  const [quantity, setQuantity] = useState("");
  const [isQuantityReadOnly, setIsQuantityReadOnly] = useState(true);
  const [usageQuantityTitle, setUsageQuantityTitle] = useState("");
  const [usageQuantity, setUsageQuantity] = useState("");
  const [isUsageQuantityHidden, setIsUsageQuantityHidden] = useState(true);
  const [cost, setCost] = useState("");
  const [isCostHidden, setIsCostHidden] = useState(true);
  const [manufacturer, setManufacturer] = useState("");
  const [isManufacturerReadOnly, setIsManufacturerReadOnly] = useState(true);
  const [buyer, setBuyer] = useState("");
  const [isBuyerHidden, setIsBuyerHidden] = useState(true);
  const [usageUser, setUsageUser] = useState("");
  const [isUsageUserHidden, setIsUsageUserHidden] = useState(true);
  const [draft, setDraft] = useState("");
  const [isDraftHidden, setIsDraftHidden] = useState(true);
  const [storage, setStorage] = useState("");
  const [isStorageReadOnly, setIsStorageReadOnly] = useState(true);
  const [note, setNote] = useState("");

  useEffect(() => {
    let userId: string = "";
    let userName: string = "";
    if (user) {
      userId = user.id;
      userName = user.name;
    }
    setRegistrant(userName);

    setIsItemCatNoReadOnly(true);
    setIsItemLotNoReadOnly(true);
    setIsItemForSaleReadOnly(true);
    setIsQuantityReadOnly(true);
    setIsUsageQuantityHidden(false);
    setIsCostHidden(true);
    setIsManufacturerReadOnly(true);
    setIsBuyerHidden(true);
    setIsUsageUserHidden(true);
    setIsDraftHidden(false);
    setIsStorageReadOnly(true);

    if (ctContext.type === "입고") {
      setIsCostHidden(false);
      setUsageQuantityTitle("입고 수량");
      if (ctContext.no === 0) {
        // 신규 입고
        setPageTitle("Cartridge 신규 입고");
        setIsItemCatNoReadOnly(false);
        setIsItemLotNoReadOnly(false);
        setIsItemForSaleReadOnly(false);
        setIsManufacturerReadOnly(false);
        setIsStorageReadOnly(false);
      } else {
        // 기존 물품 입고
        setPageTitle("Cartridge 입고");
      }
    }

    if (ctContext.type === "사용") {
      setPageTitle("Cartridge 사용");
      setUsageQuantityTitle("사용 수량");
      setIsUsageUserHidden(false);
      setIsDraftHidden(true);
    }
    if (ctContext.type === "판매") {
      setPageTitle("Cartridge 판매");
      setUsageQuantityTitle("판매 수량");
      setIsCostHidden(false);
      setIsBuyerHidden(false);
    }
    if (ctContext.type === "폐기") {
      setPageTitle("Cartridge 폐기");
      setUsageQuantityTitle("폐기 수량");
    }
    if (ctContext.type === "실재고 확인") {
      setPageTitle("Cartridge 실재고 확인");
      setIsQuantityReadOnly(false);
      setIsUsageQuantityHidden(true);
      setIsManufacturerReadOnly(false);
      setIsStorageReadOnly(false);
      setIsDraftHidden(true);
    }

    // context 변수로부터 element에 복사
    setType(ctContext.type);
    setItemCatNo(ctContext.catNo);
    setItemLotNo(ctContext.lotNo);
    setItemForSale(ctContext.forSale);
    setQuantity(ctContext.quantity);
    setUsageQuantity(ctContext.usageQuantity);
    setCost(ctContext.cost);
    setManufacturer(ctContext.manufacturer);
    setBuyer(ctContext.buyer);
    setUsageUser(ctContext.user);
    setDraft(ctContext.draft);
    setStorage(ctContext.storage);
  }, []);

  function onDateDateBoxValueChanged(e: any) {
    setDate(e);
  }
  function onItemCatNoTextBoxValueChanged(e: string) {
    setItemCatNo(e);
  }
  function onItemLotNoTextBoxValueChanged(e: string) {
    setItemLotNo(e);
  }
  function onItemForSaleCheckBoxValueChanged(e: boolean) {
    setItemForSale(e);
  }
  function onQuantityTextBoxValueChanged(e: string) {
    setQuantity(e);
  }
  function onUsageQuantityTextBoxValueChanged(e: string) {
    setUsageQuantity(e);
  }
  function onCostTextBoxValueChanged(e: string) {
    setCost(e);
  }
  function onManufacturerTextBoxValueChanged(e: string) {
    setManufacturer(e);
  }
  function onBuyerTextBoxValueChanged(e: string) {
    setBuyer(e);
  }
  function onUsageUserTextBoxValueChanged(e: string) {
    setUsageUser(e);
  }
  function onDraftTextBoxValueChanged(e: string) {
    setDraft(e);
  }
  function onStorageTextBoxValueChanged(e: string) {
    setStorage(e);
  }
  function onNoteTextBoxValueChanged(e: string) {
    setNote(e);
  }

  //////////////// Upload Button
  async function onUploadButtonClicked() {
    if (!date) {
      alert("날짜를 입력하세요!", "Error");
      return;
    }
    if (!itemCatNo) {
      alert("Cat. No를 입력하세요!", "Error");
      return;
    }
    if (!itemLotNo) {
      alert("Lot No를 입력하세요!", "Error");
      return;
    }

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let dateN: number = U.eightDigitIntDateFromDate(date);
    if (Number.isNaN(dateN)) dateN = 0;
    let quantityN: number = U.intFromString(quantity, 0);
    let usageQuantityN: number = U.intFromString(usageQuantity, 0);
    let costF: number = U.floatFromString(cost, 0);

    if (type === "실재고 확인") {
      if (usageQuantityN < 0) {
        alert("수량에 0 보다 같거나 큰 값을 입력하세요!", "Error");
        return;
      }
    } else {
      if (usageQuantityN <= 0) {
        alert("수량에 0 보다 큰 값을 입력하세요!", "Error");
        return;
      }
    }

    // "{", "}" 항목을 제거하기 위해 "(", ")"로 대체함
    let catNo2: string = U.replaceBrace(itemCatNo);
    let lotNo2: string = U.replaceBrace(itemLotNo);
    let manufacturer2: string = U.replaceBrace(manufacturer);
    let buyer2: string = U.replaceBrace(buyer);
    let user2: string = U.replaceBrace(usageUser);
    let draft2: string = U.replaceBrace(draft);
    let storage2: string = U.replaceBrace(storage);
    let note2: string = U.replaceBrace(note);

    // '\t' 제거
    let catNo3: string = catNo2.replaceAll("\t", "");
    let lotNo3: string = lotNo2.replaceAll("\t", "");

    let obj: Object = {
      itemNo: ctContext.no,
      catNo: catNo3,
      lotNo: lotNo3,
      forSale: itemForSale,
      type: type,
      date: dateN,
      registrant: registrant,
      quantity: quantityN,
      usageQuantity: usageQuantityN,
      cost: costF,
      manufacturer: manufacturer2,
      buyer: buyer2,
      user: user2,
      draft: draft2,
      storage: storage2,
      note: note2,
    };
    await uploadJson("/ct/uploadusage", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox label="분류" readOnly={true} value={type} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Cat. No."
                readOnly={isItemCatNoReadOnly}
                value={itemCatNo}
                valueChangeEvent="keyup"
                onValueChange={onItemCatNoTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"}>
              <TextBox
                label="Lot No."
                readOnly={isItemLotNoReadOnly}
                value={itemLotNo}
                valueChangeEvent="keyup"
                onValueChange={onItemLotNoTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <CheckBox text="판매용" readOnly={isItemForSaleReadOnly} value={itemForSale} onValueChange={onItemForSaleCheckBoxValueChanged} width={300} />
            </div>

            <div className={"flex-item2"}>
              <DateBox label="날짜" type="date" value={date} valueChangeEvent="change" onValueChange={onDateDateBoxValueChanged} width={300} />
            </div>
            <div className={"flex-item2"}>
              <TextBox label="등록자" readOnly={true} value={registrant} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="재고 수량"
                readOnly={isQuantityReadOnly}
                value={quantity}
                valueChangeEvent="keyup"
                onValueChange={onQuantityTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"} hidden={isUsageQuantityHidden}>
              <TextBox
                label={usageQuantityTitle}
                value={usageQuantity}
                valueChangeEvent="keyup"
                onValueChange={onUsageQuantityTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"} hidden={isCostHidden}>
              <TextBox label="금액" value={cost} valueChangeEvent="keyup" onValueChange={onCostTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="제조사"
                readOnly={isManufacturerReadOnly}
                value={manufacturer}
                valueChangeEvent="keyup"
                onValueChange={onManufacturerTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"} hidden={isBuyerHidden}>
              <TextBox label="판매처" value={buyer} valueChangeEvent="keyup" onValueChange={onBuyerTextBoxValueChanged} width={300} showClearButton={true} />
            </div>
            <div className={"flex-item2"} hidden={isUsageUserHidden}>
              <TextBox
                label="사용자"
                value={usageUser}
                valueChangeEvent="keyup"
                onValueChange={onUsageUserTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
            <div className={"flex-item2"} hidden={isDraftHidden}>
              <TextBox label="기안서" value={draft} valueChangeEvent="keyup" onValueChange={onDraftTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="저장소"
                readOnly={isStorageReadOnly}
                value={storage}
                valueChangeEvent="keyup"
                onValueChange={onStorageTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Note" value={note} valueChangeEvent="keyup" onValueChange={onNoteTextBoxValueChanged} width={300} showClearButton={true} />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
