import { createContext, useContext } from "react";
export type SmartdropContextType = {
  serial: string;
  status: string;
  modelName: string;
  purchaseDate: string;
  saleDate: string;
  manufacturer: string;
  buyer: string;
  purchasePrice: string;
  salePrice: string;
  saleDiscountRate: string;
  purchaseDraft: string;
  saleDraft: string;
  cameraModel: string;
  swVersion: string;
  mainboardVersion: string;
  licenseKey: string;
  registrant: string;
  saleManager: string;
  purchaseNote: string;
  note: string;
};
const SmartdropContext = createContext<SmartdropContextType>({} as SmartdropContextType);
const useSmartdrop = () => useContext(SmartdropContext);
export { useSmartdrop };
