import { createContext, useContext } from "react";
export type OutsourcingContextType = {
  no: string;
  name: string;
  date: string;
  supplier: string;
  totalPrice: string;
  quantity: string;
  registrant: string;
  content: string;
};
const OutsourcingContext = createContext<OutsourcingContextType>({} as OutsourcingContextType);
const useOutsourcing = () => useContext(OutsourcingContext);
export { useOutsourcing };
