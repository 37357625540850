export const bitAt = (value: number, bitNumber: number) => {
  let divider = Math.pow(2, bitNumber);
  if (value % (divider * 2) < divider) return false;
  else return true;
};

export const fixedPointLocaleStringFromFloat = (value: number, pointNumber: number) => {
  // 표시할 최대 소수점 이하 자릿수를 제한한 후 마지막 0을 자동 숨김하고 3자리 단위로 콤마를 추가한 string을 반환한다.
  // fixedPointLocaleStringFromFloat(123456.123456, 4): "123,456.1235"
  let str1: string = value.toFixed(pointNumber);
  let value2: number = parseFloat(str1);
  let str2: string = value2.toLocaleString();
  return str2;
};

export const replaceBrace = (str: string) => {
  // 중괄호 "{", "}"를 소괄호 "(", ")" 로 대체한 string을 return 한다.
  let str2: string = str.replaceAll("{", "(");
  str2 = str2.replaceAll("}", ")");
  return str2;
};

export const floatFromString = (str: string, defaultValue: number) => {
  // 입력받은 string으로부터 float을 parse 하되 문제가 있으면 default 값을 반환한다. comma는 미리 제거한 후 처리한다.
  let str2: string = str.replaceAll(",", "");
  let value: number = parseFloat(str2);
  if (Number.isNaN(value)) value = defaultValue;
  return value;
};

export const intFromString = (str: string, defaultValue: number) => {
  // 입력받은 string으로부터 int를 parse 하되 문제가 있으면 default 값을 반환한다. comma는 미리 제거한 후 처리한다.
  let str2: string = str.replaceAll(",", "");
  let value: number = parseInt(str2);
  if (Number.isNaN(value)) value = defaultValue;
  return value;
};

export const indexOfStringArray = (str: string, list: Array<string>) => {
  // 입력받은 string이 string Array의 item과 일치하면 해당 index를 반환하고 없으면 -1을 반환한다.
  if (!list) return -1;
  if (list.length === 0) return -1;
  if (str.length === 0) return -1;
  for (let i = 0; i < list.length; i++) {
    if (str === list[i]) return i;
  }
  return -1;
};

export const analyzeCloudiumLink = (str: string) => {
  // 클라우디움의 내부 배포용 URL을 복사한 경우 복사된 string 안에 있는 "http://"로 시작하는 3개의 링크를 따내서 반환한다. return [success, link1, link2, link3]
  let link1: string = "";
  let link2: string = "";
  let link3: string = "";

  // link1
  let index = str.indexOf("http://");
  if (index < 0) return [false, "", "", ""];
  str = str.substring(index);
  index = str.indexOf("\n");
  if (index < 0) return [false, "", "", ""];
  link1 = str.substring(0, index);

  // link2
  if (index + 2 >= str.length) return [false, "", "", ""];
  str = str.substring(index + 1);
  index = str.indexOf("http://");
  if (index < 0) return [false, "", "", ""];
  str = str.substring(index);
  index = str.indexOf("\n");
  if (index < 0) return [false, "", "", ""];
  link2 = str.substring(0, index);

  // link3 (마지막은 \n 없이 종료됨)
  if (index + 2 >= str.length) return [false, "", "", ""];
  str = str.substring(index + 1);
  index = str.indexOf("http://");
  if (index < 0) return [false, "", "", ""];
  link3 = str.substring(index);

  return [true, link1, link2, link3];
};

export const analyzeMultiLineIntString = (str: string) => {
  // new line으로 row가 구분되어 있고 각 row는 int와 string이 ',' 또는 ', '로 구분되어 있다.
  // //로 시작하는 comment line은 무시
  let intList: number[] = new Array();
  let strList: string[] = new Array();

  let lines = str.split("\n");
  for (let lineI = 0; lineI < lines.length; lineI++) {
    let oneLine: string = lines[lineI];
    if (oneLine.indexOf("//") == 0) continue; // comment line 무시
    let block0: string = "";
    let chr: string = "";
    let index = 0;
    for (; index < oneLine.length; index++) {
      chr = oneLine[index];
      if (chr === ",") {
        index++;
        break;
      }
      block0 += chr;
    }
    // ',' 또는 ', ' 으로 된 구분자 구간
    for (; index < oneLine.length; index++) {
      chr = oneLine[index];
      if (chr === ",") continue;
      if (chr === " ") continue;
      break;
    }
    let block1: string = "";
    for (; index < oneLine.length; index++) {
      chr = oneLine[index];
      block1 += chr;
    }
    if (block0.length === 0 || block1.length === 0) continue;
    let block0N: number = intFromString(block0, 0);
    let block1Str: string = block1;
    intList.push(block0N);
    strList.push(block1Str);
  }

  return [intList, strList];
};
