import "./csBomCalculate.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCsBom } from "../../contexts/csBom";
// api
import { queryJsonArray, deleteRowByNo } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import { CheckBox } from "devextreme-react/check-box";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function CsBomCalculate() {
  const { user } = useAuth();
  const csBomContext = useCsBom();
  const navigate = useNavigate();

  if (user) {
  }
  const [userId, setUserId] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [bomName, setBomName] = useState("");
  const [productQuantity, setProductQuantity] = useState("10");
  const [forSale, setForSale] = useState("");
  const [showShortage, setShowShortage] = useState(false);
  const [tableTitle, setTableTitle] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    setBomName(csBomContext.name);
  }, []);

  function onProductQuantityTextBoxValueChanged(e: string) {
    setProductQuantity(e);
  }

  function onForSaleSelectionChanged(e: any) {
    let str: string = e.selectedItem;
    setForSale(str);
  }

  function onShowShortageCheckBoxValueChanged(e: boolean) {
    setShowShortage(e);
  }

  function onCalculateButtonClicked() {
    if (showShortage) setTableTitle("BOM에서 재고가 부족한 품목");
    else setTableTitle("BOM의 모든 품목");
    calculate();
  }

  function calculate() {
    let multiple: number = U.intFromString(productQuantity, 0);
    if (multiple <= 0) {
      alert("생산 수량을 양수로 입력하세요!", "Error");
      return;
    }
    let isForSale: boolean = false;
    if (forSale === "판매용") isForSale = true;

    let result = U.analyzeMultiLineIntString(csBomContext.list);
    let quantities: number[] = result[0] as number[];
    let partNumbers: string[] = result[1] as string[];

    let query = `SELECT * FROM cspart`;
    queryJsonArray("/cs/querypart", query).then((jsonData: Array<Object>) => {
      let newJsonData = new Array<Object>();

      for (let i = 0; i < partNumbers.length; i++) {
        let partNumber: string = partNumbers[i];
        let requiredQuantity: number = multiple * quantities[i];
        if (requiredQuantity <= 0) continue;
        let currentQuantity: number = 0;
        // cspart에서 partNumber와 동일한 품목의 재고를 합산
        for (let jsonI = 0; jsonI < jsonData.length; jsonI++) {
          let oneRow: Object | null = jsonData[jsonI];
          let oneRowPartNumber: string = U.stringValueFromJson(oneRow, "partNumber");
          let oneRowForSale: boolean = U.booleanValueFromJson(oneRow, "forSale");
          //if (partNumber === oneRowPartNumber && isForSale === oneRowForSale) {
          if (partNumber === oneRowPartNumber) {
            let oneRowQuantity: number = U.intValueFromJson(oneRow, "quantity");
            if (forSale === "All") currentQuantity += oneRowQuantity;
            else {
              if (isForSale === oneRowForSale) currentQuantity += oneRowQuantity;
            }
          }
        }
        if (showShortage === true) {
          // 재고가 부족한 품목만 테이블에 표시
          if (requiredQuantity <= currentQuantity) continue;
        }
        let oneRowJson: Object | null = new Object();
        oneRowJson = U.addStringToJson(oneRowJson, "partNumber", partNumber);
        if (oneRowJson === null) continue;
        oneRowJson = U.addIntToJson(oneRowJson, "requiredQuantity", requiredQuantity);
        if (oneRowJson === null) continue;
        oneRowJson = U.addIntToJson(oneRowJson, "currentQuantity", currentQuantity);
        if (oneRowJson === null) continue;
        newJsonData.push(oneRowJson);
      }

      setJsonDataSource(newJsonData);
    });
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedRow(e.selectedRowsData[0]);
    }
  }

  const onCellPrepared = (e: any) => {
    if (e.rowType !== "data") return; // header를 제외한 테이블의 모든 데이터 cell
    // column의 이름
    if (e.column.caption === "현재 재고") {
      //console.log("required: " + e.data.requiredQuantity + e.data.requiredQuantity.type);
      let requiredQuantity: number = e.data.requiredQuantity as number;
      let currentQuantity: number = e.data.currentQuantity as number;
      if (requiredQuantity > currentQuantity) {
        e.cellElement.style.cssText = "color: red; text-align: right";
      }
    }
  };

  return (
    <React.Fragment>
      <h2 className={"content-block"}>CellShot BOM 생산 예측</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <TextBox label="BOM Name" value={bomName} readOnly={true} width={180} height={40} />
            </div>

            <div className={"flex-item1"}>
              <TextBox
                label="생산 수량"
                value={productQuantity}
                valueChangeEvent="keyup"
                onValueChange={onProductQuantityTextBoxValueChanged}
                width={180}
                height={40}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item1"}>
              <SelectBox
                label="판매용 부품 여부"
                dataSource={["연구용", "판매용", "All"]}
                defaultValue={"All"}
                width={160}
                height={40}
                onSelectionChanged={onForSaleSelectionChanged}
              />
            </div>

            <div className={"flex-item1"}>
              <CheckBox text="재고 부족한 부품만 표시" value={showShortage} onValueChange={onShowShortageCheckBoxValueChanged} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Calculate" onClick={onCalculateButtonClicked} type="default" />
            </div>
          </div>
          <div className={"flex-containerTitle"}>
            <p className={"text-title"}>{tableTitle}</p>
          </div>
          <div>
            <DataGrid
              onSelectionChanged={onDataGridSelectionChanged}
              dataSource={jsonDataSource}
              onCellPrepared={onCellPrepared}
              columnAutoWidth={true}
              allowColumnReordering={true}
            >
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="partNumber" caption="Part Number" />
              <Column dataField="requiredQuantity" caption="필요 수량" dataType="number" format="#,##0" />
              <Column dataField="currentQuantity" caption="현재 재고" dataType="number" format="#,##0" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="partNumber" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
