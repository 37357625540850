import { useAuth } from "./contexts/auth";
import * as U from "./utils";
// https://js.devexpress.com/React/Documentation/22_2/Guide/Themes_and_Styles/Icons/

type navigationType =
  | { text: string; path: string; icon: string; items?: undefined }
  | {
      text: string;
      path?: string;
      icon: string;
      items: { text: string; path: string }[];
    };
type itemType = { text: string; path: string };

export default function GetNavigation() {
  const { user } = useAuth();

  let acc_admin = 0,
    acc_purch = 0,
    acc_cs = 0,
    acc_proj = 0;
  if (user) {
    acc_admin = user.acc_admin;
    acc_purch = user.acc_purch;
    acc_cs = user.acc_cs;
    acc_proj = user.acc_proj;
  }

  let navigation = new Array<navigationType>();

  // 인사총괄 Category
  // (admin) [0] attendance & dailywork W, [1] Query, [2] E & D, [3] shareholder R, [4] shareholder input & D, [5] stock price R,
  // [6] dailyWork Query, [7] D, [8] progress R & W, [9] progress D, [10] patent R & W, [11] D, [12] education W & D, [13] trademark W & D, [14] certificate R, [15] W [16] D, [17] general R, [18] W [19] D
  let items = new Array<itemType>();
  // Dashboard (페이지는 모두에게 보여주고 sector 별로 권한에 따라 보여줄 지 판단함)
  items.push({
    text: "출퇴근 현황",
    path: "/attendance/dashboard",
  });

  items.push({
    text: "회의록",
    path: "/meetingMinutes",
  });

  if (U.bitAt(acc_admin, 17)) {
    items.push({
      text: "일반관리",
      path: "/general/document",
    });
  }

  if (U.bitAt(acc_admin, 6)) {
    items.push({
      text: "IoT",
      path: "/iot/dashboard",
    });
  }

  items.push({
    text: "사내교육",
    path: "/education",
  });

  if (U.bitAt(acc_admin, 10)) {
    items.push({
      text: "특허",
      path: "/patent",
    });
  }
  items.push({
    text: "상표",
    path: "/trademark",
  });
  if (U.bitAt(acc_admin, 14)) {
    items.push({
      text: "인증 및 성적서",
      path: "/certificate",
    });
  }
  if (U.bitAt(acc_admin, 3)) {
    items.push({
      text: "주주명부",
      path: "/shareholder",
    });
  }
  if (U.bitAt(acc_admin, 5)) {
    items.push({
      text: "주가",
      path: "/stockPrice",
    });
  }

  if (U.bitAt(acc_admin, 20)) {
    items.push({
      text: "DB 사용량",
      path: "/dbUsage",
    });
  }
  if (items.length > 0) {
    navigation.push({
      text: "인사총무",
      icon: "home",
      items: items,
    });
  }

  // 보고 Category
  // (proj) [0] project R (Dash), [1] W, [2] D, [3] super user
  // (admin) [0] attendance & dailywork W, [1] Query, [2] E & D
  // (admin) [6] dailyWork Query, [7] D, [8] progress R & W, [9] progress D
  items = new Array<itemType>();
  if (U.bitAt(acc_proj, 0)) {
    items.push({
      text: "프로젝트 현황",
      path: "/project/dashboard",
    });
  }
  if (U.bitAt(acc_proj, 0)) {
    items.push({
      text: "프로젝트 회의록",
      path: "/project/meeting",
    });
  }
  if (items.length > 0) {
    navigation.push({
      text: "보고",
      icon: "comment",
      items: items,
    });
  }

  // 법인자금 Category
  // (purch) [11] slip R, [12] slip input & D, [13] fundList setup, [14] fund status
  // (fund) [0] slit R, [1] slit input & D, [2] fundList, [3] fund R & W & D
  items = new Array<itemType>();
  if (U.bitAt(acc_purch, 11)) {
    items.push({
      text: "전표",
      path: "/fund/slip",
    });
  }
  if (U.bitAt(acc_purch, 13)) {
    items.push({
      text: "집계설정",
      path: "/fund/setup",
    });
  }
  if (U.bitAt(acc_purch, 14)) {
    items.push({
      text: "법인자금현황",
      path: "/fund/status",
    });
  }
  if (items.length > 0) {
    navigation.push({
      text: "법인자금",
      icon: "money",
      items: items,
    });
  }

  // 외주 발주 및 구매
  // (cs) [8] Outsourcing RW, [9] D
  if (U.bitAt(acc_cs, 8)) {
    navigation.push({
      text: "외주 발주 및 구매",
      path: "/cs/outsourcing",
      icon: "airplane",
    });
  }

  // 바이오물품 Category
  items = new Array<itemType>();
  if (acc_purch > 0) {
    // (purch) [0] purchase item R, [1] W, [2] D, [3] purchase request R, [4] W, [5] D, [6] Order, [7] inventory R, [8] usage R, [9] W, [10] D & reset
    if (U.bitAt(acc_purch, 0)) {
      items.push({
        text: "품목",
        path: "/purchase/item",
      });
    }
    if (U.bitAt(acc_purch, 3)) {
      items.push({
        text: "구매",
        path: "/purchase/request",
      });
    }
  }
  if (U.bitAt(acc_purch, 7)) {
    items.push({
      text: "재고",
      path: "/purchase/inventory",
    });
  }
  if (U.bitAt(acc_purch, 8)) {
    items.push({
      text: "입출고",
      path: "/purchase/usage",
    });
  }
  if (items.length > 0) {
    navigation.push({
      text: "Bio 연구물품",
      icon: "fill",
      items: items,
    });
  }

  // SmartDrop Category
  // (cs) [6] SmartDrop RW, [7] D
  if (U.bitAt(acc_cs, 6)) {
    items = new Array<itemType>();
    items.push({
      text: "재고",
      path: "/smartdrop",
    });
    items.push({
      text: "입고",
      path: "/smartdrop/purchase",
    });
    items.push({
      text: "출고",
      path: "/smartdrop/sale",
    });
    navigation.push({
      text: "SmartDrop 재고",
      icon: "isblank",
      items: items,
    });
  }

  // CellShot Device Category
  if (U.bitAt(acc_cs, 0)) {
    items = new Array<itemType>();
    items.push({
      text: "재고",
      path: "/csDevice",
    });
    items.push({
      text: "입고",
      path: "/csDevice/purchase",
    });
    items.push({
      text: "출고",
      path: "/csDevice/sale",
    });
    navigation.push({
      text: "CellShot Device 재고",
      icon: "square",
      items: items,
    });
  }

  // Cartridge Category
  if (U.bitAt(acc_cs, 3)) {
    items = new Array<itemType>();
    items.push({
      text: "재고",
      path: "/ct",
    });
    items.push({
      text: "입출고",
      path: "/ct/usage",
    });
    navigation.push({
      text: "Cartridge 재고",
      icon: "floppy",
      items: items,
    });
  }

  // CellShot 부품관리 Category
  if (U.bitAt(acc_cs, 0)) {
    items = new Array<itemType>();
    items.push({
      text: "BOM",
      path: "/cs/bom",
    });
    items.push({
      text: "부품 재고",
      path: "/cs/part",
    });
    items.push({
      text: "부품 입출고",
      path: "/cs/partUsage",
    });
    navigation.push({
      text: "CellShot 부품관리",
      icon: "mediumiconslayout",
      items: items,
    });
  }

  // Cartridge 부품관리 Category
  if (U.bitAt(acc_cs, 3)) {
    items = new Array<itemType>();
    items.push({
      text: "부품 재고",
      path: "/ct/part",
    });
    items.push({
      text: "부품 입출고",
      path: "/ct/partUsage",
    });
    navigation.push({
      text: "Cartridge 부품관리",
      icon: "gift",
      items: items,
    });
  }

  return navigation;
}
