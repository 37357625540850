import "./meetingMinutesNew.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useMeetingMinutes } from "../../contexts/meetingMinutes";
// api
import { uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import DateBox from "devextreme-react/date-box";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react/select-box";

export default function MeetingMinutesNew() {
  const { user } = useAuth();
  const meetingMinutesContext = useMeetingMinutes();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [no, setNo] = useState("");
  const [title, setTitle] = useState("");
  const [date, setDate] = useState<Date>();
  const [location, setLocation] = useState("");
  const [registrant, setRegistrant] = useState("");
  const [members, setMembers] = useState("");
  const [content, setContent] = useState("");

  useEffect(() => {
    if (user) {
      setUserId(user.id);
    }

    if (!meetingMinutesContext.no) {
      setNo("0");
      setRegistrant(meetingMinutesContext.registrant);
      setDate(meetingMinutesContext.date);
      return;
    }
    if (meetingMinutesContext.no.length === 0) {
      setNo("0");
      setRegistrant(meetingMinutesContext.registrant);
      setDate(meetingMinutesContext.date);
      return;
    }
    // edit 버튼으로 진입한 경우: context 변수로부터 element에 복사
    setNo(meetingMinutesContext.no);
    setTitle(meetingMinutesContext.title);
    setDate(meetingMinutesContext.date);
    setLocation(meetingMinutesContext.location);
    setRegistrant(meetingMinutesContext.registrant);
    setMembers(meetingMinutesContext.members);
    setContent(meetingMinutesContext.content);
  }, []);

  function onTitleTextBoxValueChanged(e: string) {
    setTitle(e);
  }
  function onDateTextBoxValueChanged(e: any) {
    setDate(e);
  }
  function onLocationTextBoxValueChanged(e: string) {
    setLocation(e);
  }
  function onRegistrantTextBoxValueChanged(e: string) {
    setRegistrant(e);
  }
  function onMembersTextBoxValueChanged(e: string) {
    setMembers(e);
  }
  function onContentTextAreaValueChanged(e: string) {
    setContent(e);
  }

  ///////////////////////////// Upload Button
  async function onUploadButtonClicked() {
    if (title.length === 0) {
      alert("제목을 입력하세요!", "Error");
      return;
    }
    if (location.length === 0) {
      alert("장소를 입력하세요!", "Error");
      return;
    }
    if (members.length === 0) {
      alert("참석자를 입력하세요!", "Error");
      return;
    }
    if (content.length === 0) {
      alert("내용을 입력하세요!", "Error");
      return;
    }

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let noN: number = parseInt(no);
    if (Number.isNaN(noN)) noN = 0;

    let obj: Object = {
      no: noN,
      title: title,
      date: date,
      location: location,
      registrant: registrant,
      members: members,
      content: content,
    };
    await uploadJsonAndAlert("/meetingminutes/upload", obj);
    navigate(-1);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>회의록 등록</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox label="제목" value={title} valueChangeEvent="keyup" onValueChange={onTitleTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <DateBox
                label="날짜"
                type="datetime"
                //pickerType="rollers"
                value={date}
                valueChangeEvent="change"
                onValueChange={onDateTextBoxValueChanged}
                width={300}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="장소"
                value={location}
                valueChangeEvent="keyup"
                onValueChange={onLocationTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="등록자"
                value={registrant}
                readOnly={true}
                valueChangeEvent="keyup"
                onValueChange={onRegistrantTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="참석자"
                value={members}
                valueChangeEvent="keyup"
                onValueChange={onMembersTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
          </div>

          <div className={"flex-stretch"}>
            <div className={"flex-item2"}>
              <TextArea label="내용" value={content} onValueChange={onContentTextAreaValueChanged} autoResizeEnabled={true} maxHeight={600} />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
