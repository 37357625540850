import { createContext, useContext } from "react";
export type CtContextType = {
  no: number;
  catNo: string;
  lotNo: string;
  forSale: boolean;
  type: string;
  storage: string;
  quantity: string;
  usageQuantity: string;
  cost: string;
  manufacturer: string;
  buyer: string;
  user: string;
  draft: string;
  note: string;
};
const CtContext = createContext<CtContextType>({} as CtContextType);
const useCt = () => useContext(CtContext);
export { useCt };
