import "./outsourcingNew.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useOutsourcing } from "../../contexts/outsourcing";
// api
import { uploadJson, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import DateBox from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";

export default function OutsourcingNew() {
  const { user } = useAuth();
  const outsourcingContext = useOutsourcing();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState("신규 외주 발주 및 구매");

  const [no, setNo] = useState(-1);
  const [name, setName] = useState("");
  const [date, setDate] = useState<Date>();
  const [supplier, setSupplier] = useState("");
  const [totalPrice, setTotalPrice] = useState("");
  const [quantity, setQuantity] = useState("");
  const [registrant, setRegistrant] = useState("");
  const [content, setContent] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let userName: string = "";
    if (user) {
      userName = user.name;
    }

    if (outsourcingContext.name.length == 0) {
      setPageTitle("신규 외주 발주 및 구매");
      setRegistrant(userName);
    } else {
      setPageTitle("외주 발주 및 구매 편집");
      setNo(parseInt(outsourcingContext.no));
      setName(outsourcingContext.name);
      let intDate: number = parseInt(outsourcingContext.date);
      if (U.isProperEightDigitIntDate(intDate)) setDate(U.startLocalDateFromEigitDigitIntDate(intDate));
      setSupplier(outsourcingContext.supplier);
      setTotalPrice(outsourcingContext.totalPrice);
      setQuantity(outsourcingContext.quantity);
      setRegistrant(outsourcingContext.registrant);
      setContent(outsourcingContext.content);
    }
  }, []);

  function onDateDateBoxValueChanged(e: any) {
    setDate(e);
  }
  function onNameTextBoxValueChanged(e: string) {
    setName(e);
  }
  function onSupplierTextBoxValueChanged(e: string) {
    setSupplier(e);
  }
  function onTotalPriceTextBoxValueChanged(e: string) {
    setTotalPrice(e);
  }
  function onQuantityTextBoxValueChanged(e: string) {
    setQuantity(e);
  }
  function onContentTextAreaValueChanged(e: string) {
    setContent(e);
  }

  ///////////////////////////// Upload Button
  async function onUploadButtonClicked() {
    // DateBox를 선택하지 않으면 초기값으로 undefined로 표시됨.
    if (!date) {
      alert("일자를 입력하세요!", "Error");
      return;
    }
    if (name.length === 0) {
      alert("회의록 제목을 입력하세요!", "Error");
      return;
    }

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let dateN: number = U.eightDigitIntDateFromDate(date);

    // "{", "}" 항목을 제거하기 위해 "(", ")"로 대체함
    let name2: string = U.replaceBrace(name);
    let registrant2: string = U.replaceBrace(registrant);
    let supplier2: string = U.replaceBrace(supplier);
    let totalPriceF: number = U.floatFromString(totalPrice, 0);
    let quantityN: number = U.intFromString(quantity, 0);
    let content2: string = U.replaceBrace(content);

    let obj: Object = {
      no: no,
      name: name2,
      date: dateN,
      supplier: supplier2,
      totalPrice: totalPriceF,
      quantity: quantityN,
      registrant: registrant2,
      content: content2,
    };

    await uploadJson("/cs/uploadoutsourcing", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      alert("Failed to upload!", "Error");
      return;
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox label="제목" value={name} valueChangeEvent="keyup" onValueChange={onNameTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <DateBox label="일자" type="date" value={date} valueChangeEvent="change" onValueChange={onDateDateBoxValueChanged} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="업체"
                value={supplier}
                valueChangeEvent="keyup"
                onValueChange={onSupplierTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="총액"
                value={totalPrice}
                valueChangeEvent="keyup"
                onValueChange={onTotalPriceTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="수량"
                value={quantity}
                valueChangeEvent="keyup"
                onValueChange={onQuantityTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="등록자" readOnly={true} value={registrant} width={300} showClearButton={true} />
            </div>
          </div>

          <div>
            <div className={"flex-noteItem"}>
              <TextArea
                label="상세내역"
                onValueChange={onContentTextAreaValueChanged}
                value={content}
                showClearButton={true}
                autoResizeEnabled={true}
                maxHeight={400}
                minHeight={30}
              />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
