import "./csDeviceQcNew.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// context
import { useAuth } from "../../contexts/auth";
import { useCsDevice } from "../../contexts/csDevice";
// api
import { uploadCsDeviceQcFile } from "../../api/auth";

// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";

export default function CsDeviceQcNew() {
  const { user } = useAuth();
  const csDeviceContext = useCsDevice();
  const navigate = useNavigate();

  const [file, setFile] = useState<File>();
  const [pageTitle, setPageTitle] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    setPageTitle("Serial: " + csDeviceContext.serial);
  }, []);

  const onChangeFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files) {
      let f = e.target.files[0];
      setFile(f);
    }
  };

  function onUploadButtonClicked() {
    if (!file) {
      alert("Select file first!", "Error");
      return;
    }
    uploadCsDeviceQcFile(csDeviceContext.serial, file);
    navigate(-1);
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>CellShot Device QC Report 등록</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerTitle4"}>
            <p className={"text-title"}>{pageTitle}</p>
          </div>
          <div className={"flex-containerTitle5"}>
            <p className={"text-title"}>Select pdf file</p>
          </div>
          <div className={"flex-containerV"}>
            <div className={"flex-item3"}>
              <input type="file" accept=".pdf" onChange={onChangeFile} />
            </div>
            <div className={"flex-item3"}>
              <Button text="Upload" onClick={onUploadButtonClicked} type="default" icon="upload" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
