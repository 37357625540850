import "./ctPartUsageNew.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCtPartUsage } from "../../contexts/ctPartUsage";
// api
import { uploadJson, setDeliveredPurchaseRequest } from "../../api/auth";
// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import DateBox from "devextreme-react/date-box";

export default function CtPartUsageNew() {
  const { user } = useAuth();
  const ctPartUsage = useCtPartUsage();
  const navigate = useNavigate();

  const [type, setType] = useState("");
  const [date, setDate] = useState<Date>();
  const [itemNo, setItemNo] = useState("");
  const [itemPartNumber, setItemPartNumber] = useState("");
  const [itemForSale2, setItemForSale2] = useState("");
  const [itemName, setItemName] = useState("");
  const [quantity, setQuantity] = useState("");
  const [isCostHidden, setIsCostHidden] = useState(true);
  const [cost, setCost] = useState("");
  const [registrant, setRegistrant] = useState("");
  const [isPurposeHidden, setIsPurposeHidden] = useState(true);
  const [purpose, setPurpose] = useState("");
  const [note, setNote] = useState("");
  const [tag, setTag] = useState("");

  useEffect(() => {
    let userId: string = "";
    let userName: string = "";
    if (user) {
      userId = user.id;
      userName = user.name;
    }

    // context 변수로부터 element에 복사
    setType(ctPartUsage.type);
    setRegistrant(ctPartUsage.registrant);
    setItemNo(ctPartUsage.itemNo);
    setItemPartNumber(ctPartUsage.itemPartNumber);
    if (ctPartUsage.itemForSale) setItemForSale2("판매용");
    else setItemForSale2("연구용");
    setItemName(ctPartUsage.itemName);
    setQuantity("0");
    setNote("");
    setTag(ctPartUsage.tag);

    setIsCostHidden(true);
    setIsPurposeHidden(true);
    if (ctPartUsage.type === "입고") {
      setIsCostHidden(false);
      setCost("0");
    }
    if (ctPartUsage.type === "사용") {
      setIsPurposeHidden(false);
    }
  }, []);

  function onDateDateBoxValueChanged(e: any) {
    setDate(e);
  }
  function onRegistrantTextBoxValueChanged(e: string) {
    setRegistrant(e);
  }
  function onQuantityTextBoxValueChanged(e: string) {
    setQuantity(e);
  }
  function onCostTextBoxValueChanged(e: string) {
    setCost(e);
  }
  function onPurposeTextBoxValueChanged(e: string) {
    setPurpose(e);
  }
  function onNoteTextBoxValueChanged(e: string) {
    setNote(e);
  }

  //////////////// Upload Button
  async function onUploadButtonClicked() {
    if (!date) {
      alert("날짜를 입력하세요!", "Error");
      return;
    }
    // 수량 이상 체크
    let quantityN: number = U.intFromString(quantity, 0);
    if (type === "실재고 확인") {
      if (quantityN < 0) {
        alert("수량에 0 이상의 값을 입력하세요!", "Error");
        return;
      }
    } else {
      if (quantityN < 1) {
        alert("수량에 양수를 입력하세요!", "Error");
        return;
      }
    }

    let costF: number = U.floatFromString(cost, 0);
    if (type === "입고") {
      if (costF < 0) {
        alert("구매금액에 0 이상의 값을 입력하세요!", "Error");
        return;
      }
    }

    // int or float인 column 들에 대해 string or Localed string에서 int or float으로 변환 후 upload
    let dateN: number = U.eightDigitIntDateFromDate(date);
    if (Number.isNaN(dateN)) dateN = 0;

    let obj: Object = {
      type: type,
      date: dateN,
      registrant: registrant,
      itemNo: itemNo,
      itemPartNumber: itemPartNumber,
      itemName: itemName,
      quantity: quantityN,
      cost: costF,
      purpose: purpose,
      note: note,
    };
    await uploadJson("/ct/uploadpartusage", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Cartridge 부품 입출고 등록</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox label="분류" readOnly={true} value={type} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Part Number" readOnly={true} value={itemPartNumber} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="판매용 여부" readOnly={true} value={itemForSale2} width={300} />
            </div>

            <div className={"flex-item2"}>
              <DateBox label="날짜" type="date" value={date} valueChangeEvent="change" onValueChange={onDateDateBoxValueChanged} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="수량"
                value={quantity}
                valueChangeEvent="keyup"
                onValueChange={onQuantityTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"} hidden={isCostHidden}>
              <TextBox label="구매금액" value={cost} valueChangeEvent="keyup" onValueChange={onCostTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="품명" readOnly={true} value={itemName} width={300} />
            </div>

            <div className={"flex-item2"} hidden={isPurposeHidden}>
              <TextBox
                label="사용목적"
                value={purpose}
                valueChangeEvent="keyup"
                onValueChange={onPurposeTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="등록자"
                readOnly={true}
                value={registrant}
                valueChangeEvent="keyup"
                onValueChange={onRegistrantTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="Note" value={note} valueChangeEvent="keyup" onValueChange={onNoteTextBoxValueChanged} width={300} showClearButton={true} />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
