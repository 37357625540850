import { createContext, useContext } from "react";
export type MeetingMinutesContextType = {
  no: string;
  title: string;
  date: Date;
  location: string;
  members: string;
  registrant: string;
  content: string;
};
const MeetingMinutesContext = createContext<MeetingMinutesContextType>({} as MeetingMinutesContextType);
const useMeetingMinutes = () => useContext(MeetingMinutesContext);
export { useMeetingMinutes };
