import "./csDevicePurchaseNew.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCsDevice } from "../../contexts/csDevice";
// api
import { uploadJson, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import DateBox from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";

export default function CsDevicePurchaseNew() {
  const { user } = useAuth();
  const csDeviceContext = useCsDevice();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState("");
  const [isNewMode, setIsNewMode] = useState(true);

  const [serial, setSerial] = useState("");
  const [modelName, setModelName] = useState("");
  const [purchaseDate, setPurchaseDate] = useState<Date>();
  const [manufacturer, setManufacturer] = useState("");
  const [purchasePrice, setPurchasePrice] = useState("");
  const [purchaseDraft, setPurchaseDraft] = useState("");
  const [swVersion, setSwVersion] = useState("");
  const [mainboardVersion, setMainboardVersion] = useState("");
  const [storage, setStorage] = useState("");
  const [registrant, setRegistrant] = useState("");
  const [purchaseNote, setPurchaseNote] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let userName: string = "";
    if (user) {
      userName = user.name;
    }

    if (csDeviceContext.serial.length == 0) {
      setPageTitle("신규 CellShot Device 입고");
      setIsNewMode(true);
      setRegistrant(userName);
    } else {
      setPageTitle("CellShot Device 입고 편집");
      setIsNewMode(false);
      setSerial(csDeviceContext.serial);
      let intDate: number = parseInt(csDeviceContext.purchaseDate);
      if (U.isProperEightDigitIntDate(intDate)) setPurchaseDate(U.startLocalDateFromEigitDigitIntDate(intDate));
      setModelName(csDeviceContext.modelName);
      setManufacturer(csDeviceContext.manufacturer);
      setPurchasePrice(csDeviceContext.purchasePrice);
      setPurchaseDraft(csDeviceContext.purchaseDraft);
      setSwVersion(csDeviceContext.swVersion);
      setMainboardVersion(csDeviceContext.mainboardVersion);
      setStorage(csDeviceContext.storage);
      setRegistrant(csDeviceContext.registrant);
      setPurchaseNote(csDeviceContext.purchaseNote);
    }
  }, []);

  function onSerialTextBoxValueChanged(e: string) {
    setSerial(e);
  }
  function onModelNameTextBoxValueChanged(e: string) {
    setModelName(e);
  }
  function onPurchaseDateDateBoxValueChanged(e: any) {
    setPurchaseDate(e);
  }
  function onManufacturerTextBoxValueChanged(e: string) {
    setManufacturer(e);
  }
  function onPurchasePriceTextBoxValueChanged(e: string) {
    setPurchasePrice(e);
  }
  function onPurchaseDraftTextBoxValueChanged(e: string) {
    setPurchaseDraft(e);
  }
  function onSwVersionTextBoxValueChanged(e: string) {
    setSwVersion(e);
  }
  function onMainboardVersionTextBoxValueChanged(e: string) {
    setMainboardVersion(e);
  }
  function onStorageTextBoxValueChanged(e: string) {
    setStorage(e);
  }
  function onPurchaseNoteTextAreaValueChanged(e: string) {
    setPurchaseNote(e);
  }

  ///////////////////////////// Upload Button
  async function onUploadButtonClicked() {
    // DateBox를 선택하지 않으면 초기값으로 undefined로 표시됨.
    if (!purchaseDate) {
      alert("구매일을 입력하세요!", "Error");
      return;
    }
    if (serial.length === 0) {
      alert("Serial을 입력하세요!", "Error");
      return;
    }

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let purchaseDateN: number = U.eightDigitIntDateFromDate(purchaseDate);

    // "{", "}" 항목을 제거하기 위해 "(", ")"로 대체함
    let serial2: string = U.replaceBrace(serial);
    let modelName2: string = U.replaceBrace(modelName);
    let manufacturer2: string = U.replaceBrace(manufacturer);
    let purchaseDraft2: string = U.replaceBrace(purchaseDraft);
    let swVersion2: string = U.replaceBrace(swVersion);
    let mainboardVersion2: string = U.replaceBrace(mainboardVersion);
    let storage2: string = U.replaceBrace(storage);
    let registrant2: string = U.replaceBrace(registrant);
    let purchaseNote2: string = U.replaceBrace(purchaseNote);

    let purchasePriceF: number = U.floatFromString(purchasePrice, 0);

    let obj: Object = {
      serial: serial2,
      modelName: modelName2,
      purchaseDate: purchaseDateN,
      manufacturer: manufacturer2,
      purchasePrice: purchasePriceF,
      purchaseDraft: purchaseDraft2,
      swVersion: swVersion2,
      mainboardVersion: mainboardVersion2,
      storage: storage2,
      registrant: registrant2,
      purchaseNote: purchaseNote2,
    };

    let url: string = "";
    if (isNewMode) url = "/cs/uploadnewpurchase";
    else url = "/cs/overwritepurchase";

    await uploadJson(url, obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      if (response_status === 0) {
        alert("Same serial exists!", "Error");
        return;
      }
      alert("Failed to upload!", "Error");
      return;
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox
                label="Serial"
                value={serial}
                readOnly={!isNewMode}
                valueChangeEvent="keyup"
                onValueChange={onSerialTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="모델명"
                value={modelName}
                valueChangeEvent="keyup"
                onValueChange={onModelNameTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <DateBox
                label="구매일"
                type="date"
                value={purchaseDate}
                valueChangeEvent="change"
                onValueChange={onPurchaseDateDateBoxValueChanged}
                width={300}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="생산업체"
                value={manufacturer}
                valueChangeEvent="keyup"
                onValueChange={onManufacturerTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="구매금액"
                value={purchasePrice}
                valueChangeEvent="keyup"
                onValueChange={onPurchasePriceTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="구매기안서"
                value={purchaseDraft}
                valueChangeEvent="keyup"
                onValueChange={onPurchaseDraftTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="SW 버전"
                value={swVersion}
                valueChangeEvent="keyup"
                onValueChange={onSwVersionTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="메인보드 버전"
                value={mainboardVersion}
                valueChangeEvent="keyup"
                onValueChange={onMainboardVersionTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="보관 위치"
                value={storage}
                valueChangeEvent="keyup"
                onValueChange={onStorageTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="등록자" readOnly={true} value={registrant} width={300} />
            </div>
          </div>

          <div>
            <div className={"flex-noteItem"}>
              <TextArea
                label="구매 비고"
                onValueChange={onPurchaseNoteTextAreaValueChanged}
                value={purchaseNote}
                showClearButton={true}
                autoResizeEnabled={true}
                maxHeight={400}
                minHeight={30}
              />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
