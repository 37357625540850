// react 앱을 따로 실행하면 react 앱 디버깅에는 편하지만 API 서버와 다른 origin으로 인식되서
// 주소도 IP를 추가로 적어줘야 하고 cookie로 넘겨주는 token도 cors 문제로 읽을 수 없다.
import axios from "axios";
import defaultUser from "../utils/default-user";
import { alert } from "devextreme/ui/dialog";
import { Cookies } from "react-cookie";
import * as U from "../utils";

//const preDomain = "http://localhost:80"; // for different origins (개발환경): npm start
const preDomain = ""; // for same origin (Release): npm run build

const cookies = new Cookies();

// Login
export async function sendLoginRequest(_id: string, password: string) {
  try {
    // Send request
    const uploadData = {
      id: _id,
      password: password,
    };

    let _response_status = -1;
    let _name = "";
    let _department = "";
    let _position = "";
    let _email = "";
    let _location = "";
    let _acc_admin = 0;
    let _acc_purch = 0;
    let _acc_cs = 0;
    let _acc_proj = 0;
    let _avatarUrl = "";

    await axios({
      url: `${preDomain}/login`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      const response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      const { id, name, department, position, email, location, acc_admin, acc_purch, acc_cs, acc_proj } = response.data;
      cookies.set("userId", id);

      _id = id;
      _name = name;
      _department = department;
      _position = position;
      _email = email;
      _location = location;
      _acc_admin = acc_admin;
      _acc_purch = acc_purch;
      _acc_cs = acc_cs;
      _acc_proj = acc_proj;
    });

    if (_response_status == 0) {
      // get avatar image
      await axios({
        url: `${preDomain}/login/downloadavatarimage`,
        method: "POST",
        headers: {
          ContentType: "application/json",
        },
        responseType: "blob",
        withCredentials: true,
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: "image/png" }));
        _avatarUrl = url;
      });

      return {
        isOk: true,
        data: {
          avatarUrl: _avatarUrl,
          id: _id,
          email: _email,
          location: _location,
          name: _name,
          department: _department,
          position: _position,
          acc_admin: _acc_admin,
          acc_purch: _acc_purch,
          acc_cs: _acc_cs,
          acc_proj: _acc_proj,
        },
      };
    } else if (_response_status == 1) {
      // 1: Login id does not exist
      return {
        isOk: false,
        data: defaultUser,
        message: "ID does not exist!",
      };
    } else if (_response_status == 2) {
      // 2: Login password does not match
      return {
        isOk: false,
        data: defaultUser,
        message: "Password does not match!",
      };
    } else {
      return {
        isOk: false,
        data: defaultUser,
        message: "Failed to connnect to server!",
      };
    }
  } catch {
    return {
      isOk: false,
      data: defaultUser,
      message: "Failed to connnect to server!",
    };
  }
}

export async function changePasswordAndAlert(password: string, newPassword: string) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = {
      password: password,
      newPassword: newPassword,
    };
    await axios({
      url: `${preDomain}/login/changepassword`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      const response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
    });

    if (_response_status == 0) {
      alert("Password changed!", "Success");
      return;
    } else if (_response_status == 2) {
      // 2: Login password does not match
      alert("Password does not match!", "Error");
      return;
    } else {
      alert("Failed to change password!", "Error");
      return;
    }
  } catch {
    alert("Failed to change password!", "Error");
    return;
  }
}

// General Purpose
export async function queryJsonArray(path: string, query: string) {
  // 서버회신을 await 형태로 다 동기처리했고 return  jsonData 형태이므로 queryJson을 호출한 코드에서 .then으로 return한 값을 사용한 추가작업을 이어갈 수 있다.
  let _response_status: number = -1;
  try {
    // Send request
    const uploadData = {
      query: query,
    };

    let jsonData = new Array<Object>();
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
      jsonData = response.data;
    });

    return jsonData;
  } catch {
    return new Array<Object>();
  }
}

export async function queryWithoutResultAndAlert(path: string, query: string) {
  // 서버회신을 await 형태로 다 동기처리했고 return  jsonData 형태이므로 queryJson을 호출한 코드에서 .then으로 return한 값을 사용한 추가작업을 이어갈 수 있다.
  let _response_status: number = -1;
  try {
    // Send request
    const uploadData = {
      query: query,
    };

    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {});

    if (_response_status === 0) {
      alert("Upload succeeded!", "Success");
    } else {
      alert("Failed to query!", "Error");
    }
  } catch {
    alert("Failed to query!", "Error");
    return;
  }
}

export async function downloadAndOpenPdf(path: string, fileName: string) {
  let _response_status: number = -1;
  try {
    // Send request
    const uploadData = {
      fileName: fileName,
    };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
      responseType: "blob",
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
      window.open(url);
    });
  } catch {}
}

export async function generateCompliance(userId: string, userName: string, department: string, date: string) {
  let _response_status: number = -1;
  let path: string = "/etc/generatecompliance";
  try {
    // Send request
    const uploadData = {
      userName: userName,
      department: department,
      date: date,
    };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
      responseType: "blob",
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "application/pdf" }));
      window.open(url);

      // 서버에서 pdf 생성한 후 client에서 다운로드받고 이를 다시 AWS S3 스토리지로 업로드한다.
      let file: File = response.data;
      let registrationDate: Number = U.todayEightDigitIntDate();
      uploadComplianceWithFile(userId, registrationDate, file).then(() => {});
    });
  } catch {}
}

export async function downloadAndOpenPng(path: string, fileName: string) {
  let _response_status: number = -1;
  try {
    // Send request
    const uploadData = {
      fileName: fileName,
    };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      responseType: "blob",
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "image/png" }));
      window.open(url);
    });
  } catch {}
}

export async function downloadBlobPng(id: string) {
  // cs/downloadimage 에서 셀샷 부품 이미지 다운로드
  try {
    let _url = "";

    // Send request
    const uploadData = {
      id: id,
    };

    await axios({
      url: `${preDomain}/cs/downloadimage`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      responseType: "blob",
      withCredentials: true,
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data], { type: "image/png" }));
      _url = url;
    });
    return _url;
  } catch {
    return "";
  }
}

export async function uploadJsonAndAlert(path: string, json: Object) {
  // 1차원 json (Object), 2차원 json (Array<Object>) 모두 가능
  let _response_status: number = -1;
  try {
    // Send request
    let _response_status = -1;
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: json,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {});

    if (_response_status === 0) {
      alert("Upload succeeded!", "Success");
    } else {
      alert("Failed to upload!", "Error");
    }
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

export async function uploadJson(path: string, json: Object) {
  // 1차원 json (Object), 2차원 json (Array<Object>) 모두 가능
  // return response_status 이므로 이 함수를 호출한 곳에서 아래와 같이 .then으로 작업을 이어갈 수 있다. .then((response_status: number) => {  ... });
  try {
    // Send request
    let _response_status = -1;
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: json,
      maxContentLength: Infinity,
      maxBodyLength: Infinity,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {});

    return _response_status;
  } catch {
    return -1;
  }
}

export async function updateNoteByNoAndAlert(path: string, no: number, note: string) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = { no: no, note: note };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
    });

    if (_response_status === 0) {
      alert("Updated note!", "Success");
    } else {
      alert("Failed to update note!", "Error");
    }
  } catch {}
}

export async function updateNoteByIdAndAlert(path: string, id: string, note: string) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = { id: id, note: note };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
    });

    if (_response_status === 0) {
      alert("Updated note!", "Success");
    } else {
      alert("Failed to update note!", "Error");
    }
  } catch {}
}

export async function deleteRowByNo(path: string, no: number) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = { no: no };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
    });

    if (_response_status === 0) {
      alert("Deleted!", "Success");
    } else {
      alert("Failed to delete!", "Error");
    }
  } catch {}
}

export async function deleteRowById(path: string, id: string) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = { id: id };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
    });

    if (_response_status === 0) {
      alert("Deleted!", "Success");
    } else {
      alert("Failed to delete!", "Error");
    }
  } catch {}
}

export async function deleteRowByDateAndId(path: string, date: number, id: string) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = { date: date, id: id };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
    });

    if (_response_status === 0) {
      alert("Deleted!", "Success");
    } else {
      // 20: Data id does not exist
      if (_response_status === 20) {
        alert("Data does not exist!", "Error");
      } else {
        alert("Failed to delete!", "Error");
      }
    }
  } catch {}
}

export async function deleteRowByIdBetween(path: string, id: string, timeFrom: Date, timeTo: Date) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = { id: id, timeFrom: timeFrom, timeTo: timeTo };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
    });

    if (_response_status === 0) {
      alert("Deleted!", "Success");
    } else {
      alert("Failed to delete!", "Error");
    }
  } catch {}
}

export async function deleteRowBetween(path: string, eightDigitIntDateFrom: number, eightDigitIntDateTo: number) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = { dateFrom: eightDigitIntDateFrom, dateTo: eightDigitIntDateTo };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      } else {
        if (_response_status === 0) {
          alert("Deleted!", "Success");
        } else {
          alert("Failed to delete!", "Error");
        }
      }
    });
  } catch {}
}

// Education Table
export async function uploadEducationWithFile(title: string, target: string, cycle: string, file: File) {
  try {
    // Send request
    const uploadData = {
      title: title,
      target: target,
      cycle: cycle,
    };

    let _response_status = -1;
    let _no = -1;

    await axios({
      url: `${preDomain}/education/uploadwithoutfile`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
        const { no } = response.data;
        _no = no;
      })
      .catch((err) => {});

    if (_no < 0) {
      alert("Failed to upload!", "Error");
      return;
    }
    const formData = new FormData();
    formData.append("file", file, `${_no}.pdf`);

    await axios({
      url: `${preDomain}/education/uploadfile`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "form-data",
      },
      withCredentials: true,
    });
    alert("Uploaded!", "Success");
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

// Compliance Table
export async function uploadComplianceWithFile(id: string, registrationDate: Number, file: File) {
  try {
    // Send request
    const uploadData = {
      id: id,
      registrationDate: registrationDate,
    };

    let _response_status = -1;
    let _no = -1;

    await axios({
      url: `${preDomain}/compliance/uploadwithoutfile`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
        if (_response_status === 0) {
          const { no } = response.data;
          _no = no;
        }
      })
      .catch((err) => {});

    if (_no < 0) {
      alert("Failed to upload!", "Error");
      return;
    }
    const formData = new FormData();
    formData.append("file", file, `${_no}.pdf`);

    await axios({
      url: `${preDomain}/compliance/uploadfile`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "form-data",
      },
      withCredentials: true,
    });
    alert("Uploaded!", "Success");
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

// Patent Table
export async function uploadPatentWithoutFile(
  eventType: string,
  eventDate: number,
  applicationNumber: string,
  filingDate: number,
  patentNumber: string,
  registrationDate: number,
  title: string,
  country: string,
  patentee: string,
  inventor1: string,
  inventor2: string,
  inventor3: string,
  price: number,
  remark: string
) {
  try {
    // Send request
    //console.log("EventDate", eventDate.toISOString());
    const uploadData = {
      eventType: eventType,
      eventDate: eventDate,
      applicationNumber: applicationNumber,
      filingDate: filingDate,
      patentNumber: patentNumber,
      registrationDate: registrationDate,
      title: title,
      country: country,
      patentee: patentee,
      inventor1: inventor1,
      inventor2: inventor2,
      inventor3: inventor3,
      price: price,
      remark: remark,
    };

    let _response_status = -1;
    let _no = -1;

    await axios({
      url: `${preDomain}/patent/uploadwithoutfile`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
        const { no } = response.data;
        _no = no;
      })
      .catch((err) => {});

    if (_no < 0) {
      alert("Failed to upload!", "Error");
      return;
    }
    alert("Uploaded!", "Success");
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

export async function uploadPatentWith2Files(
  eventType: string,
  eventDate: number,
  applicationNumber: string,
  filingDate: number,
  patentNumber: string,
  registrationDate: number,
  title: string,
  country: string,
  patentee: string,
  inventor1: string,
  inventor2: string,
  inventor3: string,
  price: number,
  remark: string,
  pngFile: File,
  pdfFile: File
) {
  try {
    // Send request
    const uploadData = {
      eventType: eventType,
      eventDate: eventDate,
      applicationNumber: applicationNumber,
      filingDate: filingDate,
      patentNumber: patentNumber,
      registrationDate: registrationDate,
      title: title,
      country: country,
      patentee: patentee,
      inventor1: inventor1,
      inventor2: inventor2,
      inventor3: inventor3,
      price: price,
      remark: remark,
    };

    let _response_status = -1;
    let _no = -1;

    await axios({
      url: `${preDomain}/patent/uploadwithoutfile`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
        const { no } = response.data;
        _no = no;
      })
      .catch((err) => {});

    if (_no < 0) {
      alert("Failed to upload!", "Error");
      return;
    }

    const formData = new FormData();
    formData.append("file", pngFile, `${_no}.png`);

    await axios({
      url: `${preDomain}/patent/uploadfile`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "form-data",
      },
      withCredentials: true,
    });

    const formData2 = new FormData();
    formData2.append("file", pdfFile, `${_no}.pdf`);

    await axios({
      url: `${preDomain}/patent/uploadfile`,
      method: "POST",
      data: formData2,
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "form-data",
      },
    });

    alert("Uploaded!", "Success");
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

// Trademark Table
export async function uploadTrademarkWithoutFile(
  eventType: string,
  eventDate: number,
  applicationNumber: string,
  filingDate: number,
  registrationNumber: string,
  registrationDate: number,
  title: string,
  country: string,
  price: number,
  remark: string
) {
  try {
    // Send request
    const uploadData = {
      eventType: eventType,
      eventDate: eventDate,
      applicationNumber: applicationNumber,
      filingDate: filingDate,
      registrationNumber: registrationNumber,
      registrationDate: registrationDate,
      title: title,
      country: country,
      price: price,
      remark: remark,
    };

    let _response_status = -1;
    let _no = -1;

    await axios({
      url: `${preDomain}/trademark/uploadwithoutfile`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
        const { no } = response.data;
        _no = no;
      })
      .catch((err) => {});

    if (_no < 0) {
      alert("Failed to upload!", "Error");
      return;
    }
    alert("Uploaded!", "Success");
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

export async function uploadTrademarkWithFile(
  eventType: string,
  eventDate: number,
  applicationNumber: string,
  filingDate: number,
  registrationNumber: string,
  registrationDate: number,
  title: string,
  country: string,
  price: number,
  remark: string,
  pdfFile: File
) {
  try {
    // Send request
    const uploadData = {
      eventType: eventType,
      eventDate: eventDate,
      applicationNumber: applicationNumber,
      filingDate: filingDate,
      registrationNumber: registrationNumber,
      registrationDate: registrationDate,
      title: title,
      country: country,
      price: price,
      remark: remark,
    };

    let _response_status = -1;
    let _no = -1;

    await axios({
      url: `${preDomain}/trademark/uploadwithoutfile`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
        const { no } = response.data;
        _no = no;
      })
      .catch((err) => {});

    if (_no < 0) {
      alert("Failed to upload!", "Error");
      return;
    }

    const formData = new FormData();
    formData.append("file", pdfFile, `${_no}.pdf`);

    await axios({
      url: `${preDomain}/trademark/uploadfile`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "form-data",
      },
      withCredentials: true,
    });

    alert("Uploaded!", "Success");
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

// Certificate
export async function uploadCertificateWithFile(
  registrationDate: number,
  type: string,
  title: string,
  result: string,
  expireDate: number,
  organization: string,
  cost: number,
  manager: string,
  note: string,
  pdfFile: File
) {
  try {
    // Send request
    const uploadData = {
      registrationDate: registrationDate,
      type: type,
      title: title,
      result: result,
      expireDate: expireDate,
      organization: organization,
      cost: cost,
      manager: manager,
      note: note,
    };

    let _response_status = -1;
    let _no = -1;

    await axios({
      url: `${preDomain}/certificate/uploadwithoutfile`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
        const { no } = response.data;
        _no = no;
      })
      .catch((err) => {});

    if (_no < 0) {
      alert("Failed to upload!", "Error");
      return;
    }

    const formData = new FormData();
    formData.append("file", pdfFile, `${_no}.pdf`);

    await axios({
      url: `${preDomain}/certificate/uploadfile`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "form-data",
      },
      withCredentials: true,
    });

    alert("Uploaded!", "Success");
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

export async function overwriteCertificate(
  no: number,
  registrationDate: number,
  type: string,
  title: string,
  result: string,
  expireDate: number,
  organization: string,
  cost: number,
  manager: string,
  note: string
) {
  try {
    // Send request
    const uploadData = {
      no: no,
      registrationDate: registrationDate,
      type: type,
      title: title,
      result: result,
      expireDate: expireDate,
      organization: organization,
      cost: cost,
      manager: manager,
      note: note,
    };

    let _response_status = -1;
    let _no = -1;

    await axios({
      url: `${preDomain}/certificate/overwrite`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {});

    if (_response_status !== 0) {
      alert("Failed to upload!", "Error");
      return;
    } else {
      alert("Uploaded!", "Success");
    }
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

// FundList Table
export async function uploadNewFundList(year: number, turn: number, title: string, accounts: string) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = {
      year: year,
      turn: turn,
      title: title,
      accounts: accounts,
    };

    await axios({
      url: `${preDomain}/fund/createnewlistat`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {});

    if (_response_status !== 0) {
      alert("Failed to upload!", "Error");
      return;
    }
    alert("Uploaded!", "Success");
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

export async function updateFundList(id: string, title: string, accounts: string) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = {
      id: id,
      title: title,
      accounts: accounts,
    };

    await axios({
      url: `${preDomain}/fund/updatelist`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {});

    if (_response_status !== 0) {
      alert("Failed to upload!", "Error");
      return;
    }
    alert("Uploaded!", "Success");
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

export async function swapTurnOfTwoRows(year: number, turn: number, turnToSwap: number) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = {
      year: year,
      turn: turn,
      turnToSwap: turnToSwap,
    };

    await axios({
      url: `${preDomain}/fund/swapturn`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {});

    if (_response_status !== 0) {
      alert("Failed to update!", "Error");
      return;
    }
    alert("Updated!", "Success");
  } catch {
    alert("Failed to update!", "Error");
    return;
  }
}

export async function deleteRowByYear(path: string, year: number) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = { year: year };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
    });

    if (_response_status === 0) {
      alert("Deleted!", "Success");
    } else {
      alert("Failed to delete!", "Error");
    }
  } catch {}
}

export async function uploadNewFundBalance(year: number, month: number, day: number, balance: number) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = {
      year: year,
      month: month,
      day: day,
      balance: balance,
    };

    await axios({
      url: `${preDomain}/fund/uploadbalance`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {});

    if (_response_status !== 0) {
      alert("Failed to upload!", "Error");
      return;
    }
    alert("Uploaded!", "Success");
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

// Purchase
export async function orderPurchaseRequestAndAlert(path: string, no: number, status: string, orderDate: number) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = { no: no, status: status, orderDate: orderDate };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
    });

    if (_response_status === 0) {
      alert("Ordered!", "Success");
    } else {
      alert("Failed to order!", "Error");
    }
  } catch {}
}

export async function setDeliveredPurchaseRequest(path: string, no: number, status: string, deliveryDate: number) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = { no: no, status: status, deliveryDate: deliveryDate };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
    });

    return _response_status;
  } catch {
    return -1;
  }
}

export async function resetUsageByIdAndAlert(path: string, id: string) {
  let _response_status = -1;
  try {
    // Send request
    const uploadData = { id: id };
    await axios({
      url: `${preDomain}${path}`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    }).then((response) => {
      let response_status: string = cookies.get("response_status");
      _response_status = parseInt(response_status);
      if (_response_status === 3) {
        alert("다시 로그인 하세요!", "시간초과").then(() => {
          window.location.reload();
        });
      }
    });

    if (_response_status === 0) {
      alert("Done!", "Success");
    } else {
      alert("Failed to reset!", "Error");
    }
  } catch {}
}

// CS Device
export async function uploadCsDeviceQcFile(serial: string, file: File) {
  try {
    // Send request
    const uploadData = {
      serial: serial,
    };

    let _response_status = -1;
    let _no = -1;

    await axios({
      url: `${preDomain}/cs/uploadwithoutfile`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
      })
      .catch((err) => {});

    const formData = new FormData();
    formData.append("file", file, `${serial}.pdf`);

    await axios({
      url: `${preDomain}/cs/uploadfile`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "form-data",
      },
      withCredentials: true,
    });

    alert("Uploaded!", "Success");
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

// CSPart
export async function uploadCsPartWithOrWithoutFile(
  idDate: string,
  isDisabled: boolean,
  forSale: boolean,
  type: string,
  partNumber: string,
  partName: string,
  spec: string,
  site: string,
  storage: string,
  priceF: number,
  supplier: string,
  contactPerson: string,
  contact: string,
  hyperlink: string,
  manager: string,
  note: string,
  purpose: string,
  pngFile: File | undefined
) {
  try {
    // Send request
    const uploadData = {
      idDate: idDate,
      isDisabled: isDisabled,
      forSale: forSale,
      type: type,
      partNumber: partNumber,
      partName: partName,
      spec: spec,
      site: site,
      storage: storage,
      price: priceF,
      supplier: supplier,
      contactPerson: contactPerson,
      contact: contact,
      hyperlink: hyperlink,
      manager: manager,
      note: note,
      purpose: purpose,
    };

    let _response_status = -1;
    let _id = "";

    await axios({
      url: `${preDomain}/cs/uploadpartwithoutfile`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
        const { id } = response.data;
        _id = id;
      })
      .catch((err) => {});

    if (_response_status !== 0) {
      alert("Failed to upload!", "Error");
      return;
    }

    if (pngFile === undefined) {
      return;
    }
    const formData = new FormData();
    formData.append("file", pngFile, `${_id}.png`);

    await axios({
      url: `${preDomain}/cs/uploadpartimage`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "form-data",
      },
      withCredentials: true,
    });

    alert("Uploaded!", "Success");
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

export async function overwriteCsPartWithOrWithoutFile(
  id: string,
  isDisabled: boolean,
  forSale: boolean,
  type: string,
  partNumber: string,
  partName: string,
  spec: string,
  site: string,
  storage: string,
  priceF: number,
  supplier: string,
  contactPerson: string,
  contact: string,
  hyperlink: string,
  manager: string,
  note: string,
  purpose: string,
  pngFile: File | undefined
) {
  try {
    // Send request
    const uploadData = {
      id: id,
      isDisabled: isDisabled,
      forSale: forSale,
      type: type,
      partNumber: partNumber,
      partName: partName,
      spec: spec,
      site: site,
      storage: storage,
      price: priceF,
      supplier: supplier,
      contactPerson: contactPerson,
      contact: contact,
      hyperlink: hyperlink,
      manager: manager,
      note: note,
      purpose: purpose,
    };

    let _response_status = -1;
    let _id = "";

    await axios({
      url: `${preDomain}/cs/overwritepartwithoutfile`,
      method: "POST",
      data: uploadData,
      headers: {
        ContentType: "application/json",
      },
      withCredentials: true,
    })
      .then((response) => {
        let response_status: string = cookies.get("response_status");
        _response_status = parseInt(response_status);
        if (_response_status === 3) {
          alert("다시 로그인 하세요!", "시간초과").then(() => {
            window.location.reload();
          });
        }
        const { id } = response.data;
        _id = id;
      })
      .catch((err) => {});

    if (_response_status !== 0) {
      alert("Failed to upload!", "Error");
      return;
    }

    if (pngFile === undefined) {
      return;
    }
    const formData = new FormData();
    formData.append("file", pngFile, `${_id}.png`);

    await axios({
      url: `${preDomain}/cs/uploadpartimage`,
      method: "POST",
      data: formData,
      headers: {
        "Content-Type": "multipart/form-data",
        "Content-Disposition": "form-data",
      },
      withCredentials: true,
    });

    alert("Uploaded!", "Success");
  } catch {
    alert("Failed to upload!", "Error");
    return;
  }
}

// etc
export async function test() {
  // Send request
  const uploadData = {};

  let _response_status = -1;
  await axios({
    url: `${preDomain}/login/test`,
    method: "POST",
    data: uploadData,
    headers: {
      ContentType: "application/json",
    },
    withCredentials: true,
  }).then((response) => {
    let response_status: string = cookies.get("response_status");
    _response_status = parseInt(response_status);
    if (_response_status === 3) {
      alert("다시 로그인 하세요!", "시간초과").then(() => {
        window.location.reload();
      });
    }
  });
  if (_response_status === 0) {
    alert("Test upload succeeded!", "Success");
  } else {
    alert("Test upload failed!", "Error");
  }
}

export async function getUser() {
  try {
    return {
      isOk: true,
      data: defaultUser,
    };
  } catch {
    return {
      isOk: false,
      data: defaultUser,
    };
  }
}

export async function createAccount(email: string, password: string) {
  try {
    // Send request
    console.log(email, password);

    return {
      isOk: true,
      data: defaultUser,
    };
  } catch {
    return {
      isOk: false,
      data: defaultUser,
      message: "Failed to create account",
    };
  }
}

export async function resetPassword(email: string) {
  try {
    // Send request
    console.log(email);

    return {
      isOk: true,
      data: defaultUser,
    };
  } catch {
    return {
      isOk: false,
      data: defaultUser,
      message: "Failed to reset password",
    };
  }
}
