import "./csBomNew.scss";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCsBom } from "../../contexts/csBom";
// api
import { uploadJson, setDeliveredPurchaseRequest } from "../../api/auth";
// devextreme
import { alert } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import DateBox from "devextreme-react/date-box";

export default function CsBomNew() {
  const { user } = useAuth();
  const csBomContext = useCsBom();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState("");
  const [name, setName] = useState("");
  const [isNameReadOnly, setIsNameReadOnly] = useState(true);
  const [registrant, setRegistrant] = useState("");
  const [list, setList] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let userName: string = "";
    if (user) {
      userName = user.name;
    }

    // context 변수로부터 element에 복사
    if (csBomContext.no.length == 0) {
      // new
      setPageTitle("CellShot BOM 등록");
      setIsNameReadOnly(false);
      setRegistrant(userName);
    } else {
      // edit
      setPageTitle("CellShot BOM 편집");
      setIsNameReadOnly(true);
      setName(csBomContext.name);
      setRegistrant(csBomContext.registrant);
      setList(csBomContext.list);
    }
  }, []);

  function onNameTextBoxValueChanged(e: string) {
    setName(e);
  }
  function onListTextAreaValueChanged(e: string) {
    setList(e);
  }

  //////////////// Upload Button
  async function onUploadButtonClicked() {
    if (name.length === 0) {
      alert("이름을 입력하세요!", "Error");
      return;
    }

    // int or float인 column 들에 대해 string or Localed string에서 int or float으로 변환 후 upload
    let noN: number = U.intFromString(csBomContext.no, 0);
    let name2: string = U.replaceBrace(name);
    let list2: string = U.replaceBrace(list);

    let obj: Object = {
      no: noN,
      name: name2,
      date: csBomContext.date,
      registrant: registrant,
      list: list2,
    };
    await uploadJson("/cs/uploadbom", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      } else {
        alert("Failed to upload!", "Error");
        return;
      }
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox
                label="이름"
                readOnly={isNameReadOnly}
                value={name}
                valueChangeEvent="keyup"
                onValueChange={onNameTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="등록자" readOnly={true} value={registrant} width={300} />
            </div>
          </div>

          <div className={"flex-stretch"}>
            <div className={"flex-item2"}>
              <TextArea label="BOM List (수량, Part Number)" value={list} onValueChange={onListTextAreaValueChanged} autoResizeEnabled={true} maxHeight={600} />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
