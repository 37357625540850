import { createContext, useContext } from "react";
export type CtPartUsageContextType = {
  date: string;
  type: string;
  registrant: string;
  itemNo: string;
  itemPartNumber: string;
  itemForSale: boolean;
  itemName: string;
  quantity: string;
  note: string;
  tag: string;
};
const CtPartUsageContext = createContext<CtPartUsageContextType>({} as CtPartUsageContextType);
const useCtPartUsage = () => useContext(CtPartUsageContext);
export { useCtPartUsage };
