import "./ctPart.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCtPart } from "../../contexts/ctPart";
import { useCtPartUsage } from "../../contexts/ctPartUsage";
// api
import { queryJsonArray, deleteRowById } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function CtPart() {
  const { user } = useAuth();
  const ctPart = useCtPart();
  const ctPartUsage = useCtPartUsage();
  const navigate = useNavigate();

  if (user) {
  }
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedId, setSelectedId] = useState("");
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [isUsageVisible, setIsUsageVisible] = useState(false);
  const [isNewButtonVisible, setIsNewButtonVisible] = useState(false);
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    //console.log("education.tsx: ", user?.id, user?.name, user?.acc_admin);
    let acc_cs = 0;
    if (user) {
      setUserId(user.id);
      setUserName(user.name);
      acc_cs = user.acc_cs;
    }
    // (cs) [3] Cartridge part & 완제품  R, [4] W, [5] D
    if (U.bitAt(acc_cs, 4)) setIsNewButtonVisible(true);
    else setIsNewButtonVisible(false);
    if (U.bitAt(acc_cs, 5)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);
    if (U.bitAt(acc_cs, 4)) setIsUsageVisible(true);
    else setIsUsageVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      search();
    }
  }, []);

  function onSearchButtonClicked() {
    search();
  }

  function search() {
    setSelectedId("");
    setSelectedRow(undefined);
    let query = `SELECT * FROM ctpart`;
    queryJsonArray("/ct/querypart", query).then((jsonData: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < jsonData.length; i++) {
        let oneRowJson: Object | null = jsonData[i];
        let forSale: boolean = U.booleanValueFromJson(oneRowJson, "forSale");
        let forSale2: string = "연구용";
        if (forSale) forSale2 = "판매용";
        oneRowJson = U.addStringToJson(oneRowJson, "forSale2", forSale2);
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }
      setJsonDataSource(newJsonData);
    });
  }

  function onStockInButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToCtPartUsageContext(selectedRow);
    ctPartUsage.type = "입고";
    navigate("/ct/partNewUsage");
  }

  function copyToCtPartUsageContext(obj: Object) {
    ctPartUsage.date = U.todayEightDigitStringDate();
    ctPartUsage.registrant = userName;
    ctPartUsage.itemNo = U.stringValueFromJson(obj, "no");
    ctPartUsage.itemPartNumber = U.stringValueFromJson(obj, "partNumber");
    ctPartUsage.itemForSale = U.booleanValueFromJson(obj, "forSale");
    ctPartUsage.itemName = U.stringValueFromJson(obj, "name");
  }

  function onUseButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToCtPartUsageContext(selectedRow);
    ctPartUsage.type = "사용";
    navigate("/ct/partNewUsage");
  }

  function onDestroyButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToCtPartUsageContext(selectedRow);
    ctPartUsage.type = "폐기";
    navigate("/ct/partNewUsage");
  }

  function onCheckButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToCtPartUsageContext(selectedRow);
    ctPartUsage.type = "실재고 확인";
    navigate("/ct/partNewUsage");
  }

  function onNewButtonClicked() {
    ctPart.partNumber = "";
    navigate("/ct/newPart");
  }

  function copyToCtPartContext(obj: Object) {
    ctPart.partNumber = U.stringValueFromJson(obj, "partNumber");
    ctPart.name = U.stringValueFromJson(obj, "name");
    ctPart.forSale = U.booleanValueFromJson(obj, "forSale");
    ctPart.description = U.stringValueFromJson(obj, "description");
    ctPart.supplier = U.stringValueFromJson(obj, "supplier");
    ctPart.manager = U.stringValueFromJson(obj, "manager");
  }

  function onEditButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToCtPartContext(selectedRow);
    navigate("/ct/newPart");
  }

  function onDeleteButtonClicked() {
    if (selectedId.length === 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedId, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowById("/ct/deletepart", selectedId).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedId(e.selectedRowsData[0].id);
      setSelectedRow(e.selectedRowsData[0]);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Cartridge 부품 재고현황</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>
            <div className={"flex-item1"}>
              <Button text="입고" visible={isUsageVisible} onClick={onStockInButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="사용" visible={isUsageVisible} onClick={onUseButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="폐기" visible={isUsageVisible} onClick={onDestroyButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="실재고 확인" visible={isUsageVisible} onClick={onCheckButtonClicked} />
            </div>
            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Edit" onClick={onEditButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isDeleteButtonVisible} />
            </div>
          </div>
          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="partNumber" caption="Part Number" />
              <Column dataField="forSale2" caption="판매용 여부" />
              <Column dataField="quantity" caption="재고수량" dataType="number" format="#,##0" />
              <Column dataField="name" caption="품명" />
              <Column dataField="manager" caption="사내담당자" />
              <Column dataField="supplier" caption="공급업체" />
              <Column dataField="description" caption="Description" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="partNumber" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
