import "./csDeviceSaleNew.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCsDevice } from "../../contexts/csDevice";
// api
import { uploadJson, uploadJsonAndAlert } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import DateBox from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";

export default function CsDeviceSaleNew() {
  const { user } = useAuth();
  const csDeviceContext = useCsDevice();
  const navigate = useNavigate();

  const [pageTitle, setPageTitle] = useState("");
  const [isNewMode, setIsNewMode] = useState(true);

  const [serial, setSerial] = useState("");
  const [modelName, setModelName] = useState("");
  const [saleDate, setSaleDate] = useState<Date>();
  const [manufacturer, setManufacturer] = useState("");
  const [buyer, setBuyer] = useState("");
  const [salePrice, setSalePrice] = useState("");
  const [saleDiscountRate, setSaleDiscountRate] = useState("");
  const [saleDraft, setSaleDraft] = useState("");
  const [swVersion, setSwVersion] = useState("");
  const [mainboardVersion, setMainboardVersion] = useState("");
  const [saleManager, setSaleManager] = useState("");
  const [note, setNote] = useState("");

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let userName: string = "";
    if (user) {
      userName = user.name;
    }

    if (csDeviceContext.status === "stock") {
      setPageTitle("신규 CellShot Device 출고");
      setIsNewMode(true);
      setSaleManager(userName);
      setSerial(csDeviceContext.serial);
      setModelName(csDeviceContext.modelName);
      setManufacturer(csDeviceContext.manufacturer);
      setSwVersion(csDeviceContext.swVersion);
      setMainboardVersion(csDeviceContext.mainboardVersion);
    } else {
      setPageTitle("CellShot Device 출고 편집");
      setIsNewMode(false);
      setSerial(csDeviceContext.serial);
      setModelName(csDeviceContext.modelName);
      let intDate: number = parseInt(csDeviceContext.saleDate);
      if (U.isProperEightDigitIntDate(intDate)) setSaleDate(U.startLocalDateFromEigitDigitIntDate(intDate));
      setManufacturer(csDeviceContext.manufacturer);
      setBuyer(csDeviceContext.buyer);
      setSalePrice(csDeviceContext.salePrice);
      setSaleDiscountRate(csDeviceContext.saleDiscountRate);
      setSaleDraft(csDeviceContext.saleDraft);
      setSwVersion(csDeviceContext.swVersion);
      setMainboardVersion(csDeviceContext.mainboardVersion);
      setSaleManager(csDeviceContext.saleManager);
      setNote(csDeviceContext.note);
    }
  }, []);

  function onSaleDateDateBoxValueChanged(e: any) {
    setSaleDate(e);
  }
  function onBuyerTextBoxValueChanged(e: string) {
    setBuyer(e);
  }
  function onSalePriceTextBoxValueChanged(e: string) {
    setSalePrice(e);
  }
  function onSaleDiscountRateTextBoxValueChanged(e: string) {
    setSaleDiscountRate(e);
  }
  function onSaleDraftTextBoxValueChanged(e: string) {
    setSaleDraft(e);
  }
  function onSwVersionTextBoxValueChanged(e: string) {
    setSwVersion(e);
  }
  function onMainboardVersionTextBoxValueChanged(e: string) {
    setMainboardVersion(e);
  }
  function onSaleManagerTextBoxValueChanged(e: string) {
    setSaleManager(e);
  }
  function onNoteTextAreaValueChanged(e: string) {
    setNote(e);
  }

  ///////////////////////////// Upload Button
  async function onUploadButtonClicked() {
    // DateBox를 선택하지 않으면 초기값으로 undefined로 표시됨.
    if (!saleDate) {
      alert("판매일을 입력하세요!", "Error");
      return;
    }
    if (serial.length === 0) {
      alert("Serial을 입력하세요!", "Error");
      return;
    }

    // int or float인 column 들에 대해 string에서 int or float으로 변환 후 upload
    let saleDateN: number = U.eightDigitIntDateFromDate(saleDate);

    // "{", "}" 항목을 제거하기 위해 "(", ")"로 대체함
    let serial2: string = U.replaceBrace(serial);
    let modelName2: string = U.replaceBrace(modelName);
    let buyer2: string = U.replaceBrace(buyer);
    let saleDraft2: string = U.replaceBrace(saleDraft);
    let swVersion2: string = U.replaceBrace(swVersion);
    let mainboardVersion2: string = U.replaceBrace(mainboardVersion);
    let saleManager2: string = U.replaceBrace(saleManager);
    let note2: string = U.replaceBrace(note);

    let salePriceF: number = U.floatFromString(salePrice, 0);
    let saleDiscountRateF: number = U.floatFromString(saleDiscountRate, 0);

    let obj: Object = {
      serial: serial2,
      modelName: modelName2,
      saleDate: saleDateN,
      buyer: buyer2,
      salePrice: salePriceF,
      saleDiscountRate: saleDiscountRateF,
      saleDraft: saleDraft2,
      swVersion: swVersion2,
      mainboardVersion: mainboardVersion2,
      saleManager: saleManager2,
      note: note2,
    };

    await uploadJson("/cs/uploadsale", obj).then((response_status: number) => {
      if (response_status === 0) {
        alert("Upload succeeded!", "Success");
        navigate(-1);
        return;
      }
      if (response_status === 0) {
        alert("Same serial exists!", "Error");
        return;
      }
      alert("Failed to upload!", "Error");
      return;
    });
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{pageTitle}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item2"}>
              <TextBox label="Serial" value={serial} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="모델명" value={modelName} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <DateBox label="출고일" type="date" value={saleDate} valueChangeEvent="change" onValueChange={onSaleDateDateBoxValueChanged} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="생산업체" value={manufacturer} readOnly={true} width={300} />
            </div>

            <div className={"flex-item2"}>
              <TextBox label="판매처" value={buyer} valueChangeEvent="keyup" onValueChange={onBuyerTextBoxValueChanged} width={300} showClearButton={true} />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="판매금액"
                value={salePrice}
                valueChangeEvent="keyup"
                onValueChange={onSalePriceTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="판매할인율 (%)"
                value={saleDiscountRate}
                valueChangeEvent="keyup"
                onValueChange={onSaleDiscountRateTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="판매기안서"
                value={saleDraft}
                valueChangeEvent="keyup"
                onValueChange={onSaleDraftTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="Embedded SW 버전"
                value={swVersion}
                valueChangeEvent="keyup"
                onValueChange={onSwVersionTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="메인보드 버전"
                value={mainboardVersion}
                valueChangeEvent="keyup"
                onValueChange={onMainboardVersionTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>

            <div className={"flex-item2"}>
              <TextBox
                label="판매담당자"
                value={saleManager}
                valueChangeEvent="keyup"
                onValueChange={onSaleManagerTextBoxValueChanged}
                width={300}
                showClearButton={true}
              />
            </div>
          </div>

          <div>
            <div className={"flex-noteItem"}>
              <TextArea
                label="비고"
                value={note}
                onValueChange={onNoteTextAreaValueChanged}
                showClearButton={true}
                autoResizeEnabled={true}
                maxHeight={400}
                minHeight={30}
              />
            </div>
          </div>

          <div>
            <div className={"flex-containerV"}>
              <div className={"flex-item1"}>
                <Button text="Upload" onClick={onUploadButtonClicked} width={300} type="default" icon="upload" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
