import "./meetingMinutes.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useMeetingMinutes } from "../../contexts/meetingMinutes";
// api
import { queryJsonArray, deleteRowByNo } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem } from "devextreme-react/data-grid";

export default function MeetingMinutes() {
  const { user } = useAuth();
  const meetingMinutesContext = useMeetingMinutes();
  const navigate = useNavigate();

  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");
  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedNo, setSelectedNo] = useState(-1);
  const [selectedTitle, setSelectedTitle] = useState("");
  const [selectedRegistrant, setSelectedRegistrant] = useState("");
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [content, setContent] = useState("");
  const [isContentHidden, setIsContentHidden] = useState(true);
  const [isEditButtonVisible, setIsEditButtonVisible] = useState(false);
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    let acc_proj = 0;
    if (user) {
      setUserId(user.id);
      setUserName(user.name);
      acc_proj = user.acc_proj;
    }
    // (proj) [4] 일반 회의록 D
    if (U.bitAt(acc_proj, 4)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    if (!isUseEffectExecuted) {
      // 페이지 새로 고침한 이후 1번만 실행되도록 강제
      isUseEffectExecuted = true;
      search();
    }
  }, []);

  ////////////////////////// Search Button
  function onSearchButtonClicked() {
    search();
  }
  function search() {
    //setJsonDataSource(new Array<Object>());
    setSelectedNo(-1);
    setSelectedTitle("");
    setIsContentHidden(true);

    let query = "SELECT * FROM meetingminutes";
    query += " ORDER BY date Desc";
    queryJsonArray("/meetingminutes/query", query).then((jsonData: Array<Object>) => {
      setJsonDataSource(jsonData);
    });
  }

  function onNewButtonClicked() {
    meetingMinutesContext.no = "";
    meetingMinutesContext.registrant = userName;
    meetingMinutesContext.date = new Date(Date.now());
    navigate("/meetingMinutes/new");
  }

  function onEditButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }

    meetingMinutesContext.no = selectedNo.toString();
    meetingMinutesContext.title = U.stringValueFromJson(selectedRow, "title");
    meetingMinutesContext.date = U.dateValueFromJson(selectedRow, "date");
    meetingMinutesContext.location = U.stringValueFromJson(selectedRow, "location");
    meetingMinutesContext.registrant = userName;
    meetingMinutesContext.members = U.stringValueFromJson(selectedRow, "members");
    meetingMinutesContext.content = content;
    navigate("/meetingMinutes/new");
  }

  function onDeleteButtonClicked() {
    if (selectedNo < 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedTitle, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByNo("/meetingminutes/delete", selectedNo).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedNo(e.selectedRowsData[0].no);
      setSelectedTitle(e.selectedRowsData[0].title);
      setSelectedRegistrant(e.selectedRowsData[0].registrant);
      setSelectedRow(e.selectedRowsData[0]);
      setIsContentHidden(false);
      setContent(e.selectedRowsData[0].content);
      if (e.selectedRowsData[0].registrant === userName) setIsEditButtonVisible(true);
      else setIsEditButtonVisible(false);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>회의록</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Edit" visible={isEditButtonVisible} onClick={onEditButtonClicked} />
            </div>

            <div className={"flex-item1"}>
              <Button text="Delete" visible={isDeleteButtonVisible} onClick={onDeleteButtonClicked} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="date" caption="날짜" dataType="datetime" />
              <Column dataField="title" caption="제목" />
              <Column dataField="registrant" caption="등록자" />
              <Column dataField="location" caption="장소" />
              <Column dataField="members" caption="참석자" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="date" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>

          <div className={"flex-stretch"}>
            <div className={"flex-item2"} hidden={isContentHidden}>
              <TextArea label="내용" value={content} readOnly={true} autoResizeEnabled={true} maxHeight={800} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
