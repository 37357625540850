import { createContext, useContext } from "react";
export type CsBomContextType = {
  no: string;
  name: string;
  registrant: string;
  date: string;
  list: string;
};
const CsBomContext = createContext<CsBomContextType>({} as CsBomContextType);
const useCsBom = () => useContext(CsBomContext);
export { useCsBom };
