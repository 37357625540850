import "./outsourcing.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useOutsourcing } from "../../contexts/outsourcing";
// api
import { queryJsonArray, deleteRowByNo } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";

export default function Outsourcing() {
  const { user } = useAuth();
  const outsourcingContext = useOutsourcing();
  const navigate = useNavigate();

  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [selectedNo, setSelectedNo] = useState(-1);
  const [selectedName, setSelectedName] = useState("");
  const [content, setContent] = useState("");
  const [isNewButtonVisible, setIsNewButtonVisible] = useState(false);
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);
  const [isContentHidden, setIsContentHidden] = useState(true);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let acc_cs = 0;
    if (user) {
      acc_cs = user.acc_cs;
    }

    // (cs) [8] Outsourcing RW, [9] D
    if (U.bitAt(acc_cs, 8)) setIsNewButtonVisible(true);
    else setIsNewButtonVisible(false);
    if (U.bitAt(acc_cs, 9)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    search();
  }, []);

  ////////////////////////// Search Button
  function onSearchButtonClicked() {
    search();
  }
  function onNewButtonClicked() {
    outsourcingContext.name = "";
    navigate("/cs/outsourcing/new");
  }
  function onEditButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToOutsourcingContext(selectedRow);
    navigate("/cs/outsourcing/new");
  }
  function copyToOutsourcingContext(obj: Object) {
    outsourcingContext.no = U.stringValueFromJson(obj, "no");
    outsourcingContext.name = U.stringValueFromJson(obj, "name");
    outsourcingContext.date = U.stringValueFromJson(obj, "date");
    outsourcingContext.supplier = U.stringValueFromJson(obj, "supplier");
    outsourcingContext.totalPrice = U.stringValueFromJson(obj, "totalPrice");
    outsourcingContext.quantity = U.stringValueFromJson(obj, "quantity");
    outsourcingContext.registrant = U.stringValueFromJson(obj, "registrant");
    outsourcingContext.content = U.stringValueFromJson(obj, "content");
  }

  function onDeleteButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedName, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByNo("/cs/deleteoutsourcing", selectedNo).then(() => {
          onSearchButtonClicked();
          setIsContentHidden(true);
        });
      } else {
        // No
      }
    });
  }
  function search() {
    //setSelectedNo(-1);
    //setIsContentHidden(true);

    let query = "SELECT * FROM outsourcing";
    query += " ORDER BY date Desc, no Desc";
    queryJsonArray("/cs/queryoutsourcing", query).then((jsonData: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < jsonData.length; i++) {
        let oneRowJson: Object | null = jsonData[i];
        let date: number = U.intValueFromJson(oneRowJson, "date");
        let date2: string = U.stringDateFromEightDigitIntDate(date);
        oneRowJson = U.addStringToJson(oneRowJson, "date2", date2);
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }
      setJsonDataSource(newJsonData);
    });
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedRow(e.selectedRowsData[0]);
      setSelectedNo(e.selectedRowsData[0].no);
      setSelectedName(e.selectedRowsData[0].name);
      setIsContentHidden(false);
      setContent(e.selectedRowsData[0].content);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>외주 발주 및 구매</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>

            <div className={"flex-item1"}>
              <Button text="New" onClick={onNewButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Edit" onClick={onEditButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isDeleteButtonVisible} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <Paging defaultPageSize={10} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="date2" caption="일자" />
              <Column dataField="name" caption="제목" />
              <Column dataField="supplier" caption="업체" />
              <Column dataField="totalPrice" caption="총액" dataType="number" format="#,##0" />
              <Column dataField="quantity" caption="수량" dataType="number" format="#,##0" />
              <Column dataField="registrant" caption="등록자" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="date2" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>

          <div className={"flex-stretch"}>
            <div className={"flex-item2"} hidden={isContentHidden}>
              <TextArea label="내용" value={content} readOnly={true} autoResizeEnabled={true} maxHeight={800} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
