import "./ct.scss";
import React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as U from "../../utils";
// context
import { useAuth } from "../../contexts/auth";
import { useCt } from "../../contexts/ct";
// api
import { queryJsonArray, deleteRowByNo, downloadAndOpenPdf } from "../../api/auth";
// devextreme
import { alert, confirm } from "devextreme/ui/dialog";
import { Button } from "devextreme-react/button";
import { TextBox } from "devextreme-react/text-box";
import TextArea from "devextreme-react/text-area";
import { SelectBox } from "devextreme-react/select-box";
import { DataGrid, Column, Selection, LoadPanel, SearchPanel, Export, Summary, TotalItem, Paging } from "devextreme-react/data-grid";

export default function Ct() {
  const { user } = useAuth();
  const ctContext = useCt();
  const navigate = useNavigate();

  const [jsonDataSource, setJsonDataSource] = useState<Array<Object>>();
  const [selectedRow, setSelectedRow] = useState<Object>();
  const [selectedNo, setSelectedNo] = useState(0);
  const [selectedUid, setSelectedUid] = useState("");
  const [isNewButtonVisible, setIsNewButtonVisible] = useState(false);
  const [isDeleteButtonVisible, setIsDeleteButtonVisible] = useState(false);

  // 전역변수는 페이지 로딩 시 useEffect가 2회 반복실행되는 동안만 assign한 값이 유지되고 그 직후 초기화된다.
  let isUseEffectExecuted: boolean = false;

  useEffect(() => {
    // 페이지 새로 고침한 이후 1번만 실행되도록 강제
    if (isUseEffectExecuted) return;
    isUseEffectExecuted = true;

    let acc_cs = 0;
    if (user) {
      acc_cs = user.acc_cs;
    }

    // (cs) [3] Cartridge part & 완제품  R, [4] W, [5] D
    if (U.bitAt(acc_cs, 4)) setIsNewButtonVisible(true);
    else setIsNewButtonVisible(false);
    if (U.bitAt(acc_cs, 5)) setIsDeleteButtonVisible(true);
    else setIsDeleteButtonVisible(false);

    search();
  }, []);

  ////////////////////////// Search Button
  function onSearchButtonClicked() {
    search();
  }
  function search() {
    let query = "SELECT * FROM ct";
    query += " ORDER BY catNo Asc, lotNo Asc";
    queryJsonArray("/ct/query", query).then((jsonData: Array<Object>) => {
      let newJsonData = new Array<Object>();
      for (let i = 0; i < jsonData.length; i++) {
        let oneRowJson: Object | null = jsonData[i];
        let forSale: boolean = U.booleanValueFromJson(oneRowJson, "forSale");
        let forSale2: string = "연구용";
        if (forSale) forSale2 = "판매용";
        oneRowJson = U.addStringToJson(oneRowJson, "forSale2", forSale2);
        if (oneRowJson !== null) newJsonData.push(oneRowJson);
      }
      setJsonDataSource(newJsonData);
    });
  }

  function onNewPurchaseButtonClicked() {
    clearCtContext();
    ctContext.no = 0;
    ctContext.type = "입고";
    ctContext.quantity = "0";
    navigate("/ct/usage/new");
  }

  function onDeleteButtonClicked() {
    if (selectedNo <= 0) {
      alert("Select row first!", "Error");
      return;
    }
    let res = confirm(selectedUid, "Delete?");
    res.then((dialogResult) => {
      if (dialogResult) {
        // Yes
        deleteRowByNo("/ct/delete", selectedNo).then(() => {
          onSearchButtonClicked();
        });
      } else {
        // No
      }
    });
  }

  function onPurchaseButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToCtContext(selectedRow);
    ctContext.type = "입고";
    navigate("/ct/usage/new");
  }

  function onUseButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToCtContext(selectedRow);
    ctContext.type = "사용";
    navigate("/ct/usage/new");
  }

  function onSaleButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToCtContext(selectedRow);
    ctContext.type = "판매";
    navigate("/ct/usage/new");
  }

  function onDestroyButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToCtContext(selectedRow);
    ctContext.type = "폐기";
    navigate("/ct/usage/new");
  }

  function onCheckButtonClicked() {
    if (!selectedRow) {
      alert("Select row first!", "Error");
      return;
    }
    copyToCtContext(selectedRow);
    ctContext.type = "실재고 확인";
    navigate("/ct/usage/new");
  }
  function clearCtContext() {
    ctContext.catNo = "";
    ctContext.lotNo = "";
    ctContext.forSale = false;
    ctContext.type = "";
    ctContext.storage = "";
    ctContext.quantity = "";
    ctContext.usageQuantity = "";
    ctContext.cost = "";
    ctContext.manufacturer = "";
    ctContext.buyer = "";
    ctContext.user = "";
    ctContext.draft = "";
    ctContext.note = "";
  }

  function copyToCtContext(obj: Object) {
    clearCtContext();
    ctContext.no = U.intValueFromJson(obj, "no");
    ctContext.catNo = U.stringValueFromJson(obj, "catNo");
    ctContext.lotNo = U.stringValueFromJson(obj, "lotNo");
    ctContext.forSale = U.booleanValueFromJson(obj, "forSale");
    ctContext.manufacturer = U.stringValueFromJson(obj, "manufacturer");
    ctContext.storage = U.stringValueFromJson(obj, "storage");
    ctContext.quantity = U.stringValueFromJson(obj, "quantity");
  }

  function onDataGridSelectionChanged(e: any) {
    if (e.selectedRowsData.length != 0) {
      setSelectedRow(e.selectedRowsData[0]);
      setSelectedNo(e.selectedRowsData[0].no);
      let uid: string = e.selectedRowsData[0].catNo;
      uid += "-";
      uid += e.selectedRowsData[0].lotNo;
      uid += "-";
      if (e.selectedRowsData[0].forSale === true) uid += "판매용";
      else uid += "연구용";
      setSelectedUid(uid);
    }
  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>Cartridge 재고</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className={"flex-containerH"}>
            <div className={"flex-item1"}>
              <Button text="Search" onClick={onSearchButtonClicked} type="default" icon="download" />
            </div>
            <div className={"flex-item1"}>
              <Button text="신규입고" onClick={onNewPurchaseButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="Delete" onClick={onDeleteButtonClicked} visible={isDeleteButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="입고" onClick={onPurchaseButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="사용" onClick={onUseButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="판매" onClick={onSaleButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="폐기" onClick={onDestroyButtonClicked} visible={isNewButtonVisible} />
            </div>
            <div className={"flex-item1"}>
              <Button text="실재고 확인" onClick={onCheckButtonClicked} visible={isNewButtonVisible} />
            </div>
          </div>

          <div>
            <DataGrid onSelectionChanged={onDataGridSelectionChanged} dataSource={jsonDataSource} columnAutoWidth={true} allowColumnReordering={true}>
              <Paging defaultPageSize={20} />
              <LoadPanel enabled />
              <Selection mode="single" />
              <Column dataField="catNo" caption="Cat. No." />
              <Column dataField="lotNo" caption="Lot No." />
              <Column dataField="forSale2" caption="판매용 여부" />
              <Column dataField="quantity" caption="재고수량" dataType="number" format="#,##0" />
              <Column dataField="manufacturer" caption="제조사" />
              <Column dataField="storage" caption="보관 위치" />
              <SearchPanel visible={true} width={300} placeholder={"Find..."} />
              <Export enabled={true} allowExportSelectedData={false} />
              <Summary>
                <TotalItem column="catNo" summaryType="count" valueFormat="#,##0" />
              </Summary>
            </DataGrid>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
